import Date from '../assets/utils/Date';
import AdminV2Repository from "./AdminV2Repository";

export default class VisaRepository extends AdminV2Repository {
    getTableData(page, pageSize, sorted, filter) {
        return this.post(
            '/recurrentes',
            JSON.stringify({
                page,
                pageSize,
                sorted,
                filter,
            }),
        );
    }

    updateRecurrentVisa({ userId, date, amount, months, discountPercent, active }) {
        date = Date.formatDateToString(date);
        return this.post(
            '/recurrente',
            JSON.stringify({
                userId,
                date,
                amount,
                months,
                discountPercent,
                active,
            }),
        );
    }
}
