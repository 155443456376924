class YesNo {
    static SI = 'Sí';
    static NO = 'No';

    static SI_KEY = '1';
    static NO_KEY = '0';

    static data = {
        [YesNo.SI_KEY]: YesNo.SI,
        [YesNo.NO_KEY]: YesNo.NO,
    };

    static getOptions = () => {
        return [YesNo.getOptionOfKey(YesNo.SI_KEY), YesNo.getOptionOfKey(YesNo.NO_KEY)];
    };

    static getOptionFromBoolean = (enabled) => {
        if (enabled) {
            return YesNo.getOptionOfKey(YesNo.SI_KEY);
        } else {
            return YesNo.getOptionOfKey(YesNo.NO_KEY);
        }
    };

    static getOptionOfKey = (value) => {
        return {
            value: value,
            label: YesNo.getLabelOf(value),
        };
    };

    static getLabelOf = (key) => {
        return YesNo.data[key];
    };
}

export default YesNo;
