import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import Date from '../assets/utils/Date';
import VisaRepository from '../repositories/VisaRepository';
import RecurrentVisaFormPart from '../components/FormParts/RecurrentVisaFormPart';
import { ModalNotification, Content, ContentContainer } from 'book';

class RecurrentVisa extends Component {
    constructor(props) {
        super(props);

        this.visaRepository = new VisaRepository();
        this.refTable = null;

        this.state = {
            data: [],
            pages: -1,
            loading: false,
            modalEditShown: false,
            modalEditData: {},
        };
    }

    fetchData = (state, instance) => {
        this.setState({ loading: true });
        this.visaRepository.getTableData(state.page, state.pageSize, state.sorted, state.filtered).then((response) => {
            this.setState({
                data: response.data.rows,
                pages: response.data.pages,
                loading: false,
            });
        });
    };

    openModalEdit = (data) => {
        this.setState({
            modalEditShown: true,
            modalEditData: data,
        });
    };

    closeModalEdit = () => {
        this.setState({
            modalEditShown: false,
            modalEditData: {},
        });
    };

    updateDate = (date) => {
        if (date && date.isValid()) {
            this.setState({
                modalEditData: { ...this.state.modalEditData, date: date },
            });
        }
    };

    updateCantidad = (value) => {
        if (!isNaN(value)) {
            this.setState({
                modalEditData: { ...this.state.modalEditData, amount: value },
            });
        }
    };

    updateCuotas = (value) => {
        if (!isNaN(value)) {
            this.setState({
                modalEditData: { ...this.state.modalEditData, months: value },
            });
        }
    };

    updateDiscount = (value) => {
        if (!isNaN(value)) {
            this.setState({
                modalEditData: { ...this.state.modalEditData, discountPercent: value },
            });
        }
    };

    updateActive = (value) => {
        this.setState({
            modalEditData: { ...this.state.modalEditData, active: value },
        });
    };

    render() {
        return (
            <>
                <ModalNotification
                    id={'modal-recurrent'}
                    title={'Editar datos'}
                    actionText={'Guardar'}
                    isOpen={this.state.modalEditShown}
                    handleAction={() => {
                        if (this.refModalData.isValidated()) {
                            this.visaRepository.updateRecurrentVisa(this.state.modalEditData).then((response) => {
                                if (response.success) {
                                    this.fetchData(this.refTable.state);
                                    this.closeModalEdit();
                                }
                            });
                        }
                    }}
                    handleClose={this.closeModalEdit}
                >
                    <RecurrentVisaFormPart
                        date={this.state.modalEditData.date}
                        cantidad={this.state.modalEditData.amount}
                        cuotas={this.state.modalEditData.months}
                        active={this.state.modalEditData.active}
                        discountPercent={this.state.modalEditData.discountPercent}
                        updateDate={this.updateDate}
                        updateCantidad={this.updateCantidad}
                        updateCuotas={this.updateCuotas}
                        updateDiscount={this.updateDiscount}
                        updateActive={this.updateActive}
                        ref={(ref) => {
                            if (ref) {
                                this.refModalData = ref;
                            }
                        }}
                    />
                </ModalNotification>
                <Content id="recurrentesVisa" title={'Gestión de recurrencias VISA'}>
                    <ContentContainer>
                        <Row>
                            <Col xs={12}>
                                <ReactTable
                                    columns={[
                                        {
                                            Header: 'ID',
                                            accessor: 'userId',
                                        },
                                        {
                                            Header: 'Próximo Cobro',
                                            accessor: 'date',
                                        },
                                        {
                                            Header: 'Cuotas/cobro',
                                            accessor: 'months',
                                        },
                                        {
                                            Header: 'Precio',
                                            accessor: 'amount',
                                        },
                                        {
                                            Header: 'Precio Inicial sin IVA',
                                            accessor: 'orderAmount',
                                        },
                                        {
                                            Header: '% Descuento',
                                            accessor: 'discountPercent',
                                        },
                                        {
                                            Header: 'Descuento fijo mensual',
                                            accessor: 'discountAmount',
                                        },
                                        {
                                            Header: 'Precio Final con IVA',
                                            accessor: 'totalAmount',
                                        },
                                        {
                                            Header: 'Activa',
                                            accessor: 'active',
                                            Cell: (row) => {
                                                if (row.value) {
                                                    return <span style={{ color: 'green' }}>Activa</span>;
                                                } else {
                                                    return <span style={{ color: 'red' }}>Inactiva</span>;
                                                }
                                            },
                                        },
                                        {
                                            Header: '',
                                            accessor: null,
                                            filterable: false,
                                            sortable: false,
                                            Cell: (row) => {
                                                const d = row.original;
                                                const data = {
                                                    userId: d.userId,
                                                    date: Date.formatStringToDate(d.date),
                                                    amount: d.amount,
                                                    months: d.months,
                                                    discountPercent: d.discountPercent,
                                                    active: d.active,
                                                };
                                                return (
                                                    <Button onClick={() => this.openModalEdit(data)}>
                                                        <i className={'fa fa-edit'}></i>
                                                    </Button>
                                                );
                                            },
                                        },
                                    ]}
                                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                    data={this.state.data}
                                    pages={this.state.pages} // Display the total number of pages
                                    loading={this.state.loading} // Display the loading overlay when we need it
                                    onFetchData={this.fetchData} // Request new data when things change
                                    filterable
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                    ref={(ref) => (this.refTable = ref)}
                                />
                            </Col>
                        </Row>
                    </ContentContainer>
                </Content>
            </>
        );
    }
}

export default RecurrentVisa;
