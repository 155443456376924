import {useEffect, useRef, useState} from "react";
import {ContabilidadV2Repository} from "../../../../repositories/ContabilidadV2Repository";




export const useModalReglas = () => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
    const [userId, setUserId] = useState(null);
    const [year, setYear] = useState(null);
    const [busy, setBusy] = useState(false);
    const toastRef = useRef();

    const repository = new ContabilidadV2Repository();

    const currentYear = new Date().getFullYear();
    const yearsArray = Array.from({length: 5}, (_, i) => {
        const year = currentYear - i;
        return {label: year.toString(), value: year.toString()};
    });

    const openForm = (userId) => {
        setUserId(userId);
        setShow(true);
        setYear(currentYear);

    };

    const closeForm = () => {
        setUserId(null);
        setShow(false);
        setData(null);
        setYear(null);
    };

    const getData = async () => {
        setBusy(true);
        setData(null);
        repository.getReglasContabilidad(userId, year).then(data => {
            setData(data);
        }).catch(e => {
            toastRef.current.notifyError(e.message);
        }).finally(() => {
            setBusy(false);
        })
    };

    useEffect(() => {
        if (show) {
            getData();
        }
        // eslint-disable-next-line
    }, [show, year]);

    const updateTrimestre = async (key, value) => {
        setBusy(true);
       try {
           await repository.postReglasContabilidad(userId, key, String(year), value)
           setData({
               ...data,
               [key]: {desactivadas: value},
           });
           toastRef.current.notifySuccess(" ", "Reglas de bloqueo de contabilidad actualizadas");
       } catch (e) {
           toastRef.current.notifyError(" ", "Error en la actualización de reglas de bloqueo de contabilidad", false);
       }
        setBusy(false);

        setTimeout(() => {

        }, 2000);
    };

    return {
        show,
        setShow,
        data,
        year,
        setYear,
        busy,
        yearsArray,
        openForm,
        closeForm,
        updateTrimestre,
        toastRef
    };
};
