import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import AdvicesRepository from '../../repositories/AdvicesRepository';
import AdviceStatus from '../../components/ListsOptions/AdviceStatus';
import FilterTab from '../../components/FilterTab';
import AdviceModal from './AdviceModal';
import AdviserAdviceTable from './AdviserAdviceTable';
import { Content, ContentToolbelt, ContentContainer, ActionButton, Label, Input, LinkButton, Spinner } from 'book';
import {AdvicesV2Repository} from "../../repositories/AdvicesV2Repository";

class Advices extends Component {
    constructor(props) {
        super(props);

        this.adviceRepository = new AdvicesRepository();
        this.adviceV2Repository = new AdvicesV2Repository();

        this.reloadAdvices = _.debounce(this.loadAdvices, 300);

        this.state = {
            page: 0,
            pageRows: 10,
            addAdviceModalOpen: false,
            editAdviceModalOpen: false,
            confirmDeleteModalOpen: false,
            deleteAdviceId: null,
            advices: [],
            adviceId: null,
            statusFilter: AdviceStatus.ALL_KEY,
            sections: [],
            agents: [],
            errorMessage: '',
            advicesFilter: '',
            isValidForm: false,
            loadingAdvices: false,
        };
    }

    componentDidMount() {
        this.loadAdvices();
        this.loadSections();
        this.loadAgents();
    }

    loadAdvices = () => {
        this.setState(() => ({ loadingAdvices: true }));
        this.adviceRepository
            .getAdvices(this.state.page, this.state.pageRows, this.state.advicesFilter, this.state.statusFilter)
            .then((response) => {
                if (response.success) {
                    this.setState({
                        advices: response.data,
                    });
                }
            })
            .finally(() => this.setState(() => ({ loadingAdvices: false })));
    };

    loadSections = () => {
        this.adviceV2Repository.getSections().then((response) => {
            let data = response.data != null ? response.data : [];
            this.setState({
                sections: data,
            });
        });
    };

    loadAgents = () => {
        this.adviceV2Repository.getAgents().then((response) => {
            if (response.success) {
                this.setState({
                    agents: response.data,
                });
            }
        });
    };

    handleNextPage() {
        let page = this.state.page;
        this.setState(
            {
                page: page + 1,
            },
            this.loadAdvices,
        );
    }

    handlePreviousPage() {
        let page = this.state.page - 1;
        if (page < 0) page = 0;
        this.setState(
            {
                page: page,
            },
            this.loadAdvices,
        );
    }

    handleAdvicesFilterChange = (event) => {
        this.setState(
            {
                advicesFilter: event.target.value,
            },
            this.reloadAdvices,
        );
    };

    handleStatusFilterChange = (value) => {
        this.setState(
            {
                statusFilter: value,
            },
            this.reloadAdvices,
        );
    };

    handleAddition = () => {
        this.setState({
            adviceId: null,
            addAdviceModalOpen: true,
        });
    };

    addAdvice = (advice) => {
        this.adviceRepository.saveAdvice(advice).then((response) => {
            if (response.success) {
                this.closeAddAdviceModal();
                this.loadAdvices();
            } else {
                this.setState({
                    errorMessage: response.message,
                });
            }
        });
    };

    closeAddAdviceModal = () => {
        this.setState({
            addAdviceModalOpen: false,
        });
    };

    handleEdition = (adviceId) => {
        this.setState({
            editAdviceModalOpen: true,
            adviceId: adviceId,
        });
    };

    editAdvice = (advice) => {
        this.adviceRepository.saveAdvice(advice).then((response) => {
            if (response.success) {
                this.closeEditAdviceModal();
                this.loadAdvices();
            } else {
                this.setState({
                    errorMessage: response.message,
                });
            }
        });
    };

    closeEditAdviceModal = () => {
        this.setState({
            adviceId: null,
            editAdviceModalOpen: false,
        });
    };

    handleDelete = (adviceId) => {
        this.setState({
            deleteAdviceId: adviceId,
            confirmDeleteModalOpen: true,
        });
    };

    toggleStatus = (adviceId, status) => {
        this.adviceRepository.toggleAdviceStatus(adviceId, status).then((response) => {
            if (response.success) {
                this.loadAdvices();
            } else {
                this.setState({
                    errorMessage: response.message,
                });
            }
        });
    };

    toggleConfirmDeleteModal = () => {
        this.setState({
            confirmDeleteModalOpen: !this.state.confirmDeleteModalOpen,
        });
    };

    deleteAdvice = () => {
        this.adviceRepository.deleteAdvice(this.state.deleteAdviceId).then((response) => {
            if (response.success) {
                this.toggleConfirmDeleteModal();
                this.loadAdvices();
            } else {
                this.setState({
                    errorMessage: response.message,
                });
            }
        });
    };

    render() {
        return (
            <>
                <Content id="consejos" title={'Listado de consejos del Asesor'}>
                    <ContentToolbelt>
                        <Row>
                            <Col sm={12}>
                                <ActionButton
                                    title={'Añadir consejo'}
                                    small
                                    className={'pull-right font-weight-500'}
                                    onClick={this.handleAddition}
                                >
                                    Añadir consejo
                                    <i className={'fa fa-info-circle ml-p5'} />
                                </ActionButton>
                            </Col>
                        </Row>
                    </ContentToolbelt>
                    <ContentContainer>
                        <Row>
                            <Col className={'mb-1'} xs={12} sm={3} md={4}>
                                <Label theme={'primary'}>Filtrar por contenido</Label>
                                <Input
                                    type="text"
                                    name="filterAdvices"
                                    className={'mb-0'}
                                    placeholder="Escribe algo..."
                                    value={this.state.advicesFilter}
                                    onChange={this.handleAdvicesFilterChange}
                                />
                            </Col>
                        </Row>
                        <Row className={'mt-4 mb-2'}>
                            <Col xs={12}>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        padding: '0',
                                        background: 'rgb(233, 234, 242)',
                                        borderRadius: '24px',
                                    }}
                                >
                                    {AdviceStatus.data.map((status) => {
                                        return (
                                            <FilterTab
                                                className={''}
                                                title={status.label}
                                                handleStatusFilterChange={this.handleStatusFilterChange}
                                                filter={status.key}
                                                selected={this.state.statusFilter === status.key}
                                            />
                                        );
                                    })}
                                </span>
                            </Col>
                        </Row>
                        <Row className={'mb-2'}>
                            <Col xs={12}>
                                {this.state.loadingAdvices ? (
                                    <div
                                        style={{
                                            border: '1px solid gainsboro',
                                        }}
                                    >
                                        <Spinner onlyloader={false} />
                                    </div>
                                ) : this.state.advices.length ? (
                                    <AdviserAdviceTable
                                        advices={this.state.advices}
                                        handleEdition={this.handleEdition}
                                        handleDelete={this.handleDelete}
                                        toggleStatus={this.toggleStatus}
                                    />
                                ) : (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: '1px solid #E3E3E3',
                                            height: '300px',
                                        }}
                                    >
                                        <span className="text-left d-inline-block">
                                            <p className="h4 font-weight-600 m-0">No se han encontrado resultados</p>
                                        </span>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <LinkButton
                                    id={'page-previous'}
                                    className={'pull-left font-weight-500'}
                                    onClick={this.handlePreviousPage.bind(this)}
                                >
                                    ⬅ Anterior
                                </LinkButton>
                                <LinkButton
                                    id={'page-next'}
                                    className={'pull-right font-weight-500'}
                                    onClick={this.handleNextPage.bind(this)}
                                >
                                    Siguiente ⮕
                                </LinkButton>
                            </Col>
                        </Row>
                    </ContentContainer>
                </Content>
                <AdviceModal
                    modalId="add-Advice-modal"
                    title="Nuevo Consejo"
                    modalOpen={this.state.addAdviceModalOpen}
                    toggleModal={this.closeAddAdviceModal}
                    handleSubmit={this.addAdvice}
                    adviceId={this.state.adviceId}
                    sections={this.state.sections}
                    agents={this.state.agents}
                />

                <AdviceModal
                    modalId="edit-Advice-modal"
                    title="Modificar Consejo"
                    modalOpen={this.state.editAdviceModalOpen}
                    toggleModal={this.closeEditAdviceModal}
                    handleSubmit={this.editAdvice}
                    adviceId={this.state.adviceId}
                    sections={this.state.sections}
                    agents={this.state.agents}
                />

                <Modal id="delete-modal" show={this.state.confirmDeleteModalOpen}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar borrado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>¿Estás seguro de eliminar el consejo?</Modal.Body>
                    <Modal.Footer>
                        <Button id="delete-button" className={'btn-danger'} onClick={this.deleteAdvice}>
                            Eliminar
                        </Button>
                        <Button onClick={this.toggleConfirmDeleteModal}>Cancelar</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default Advices;
