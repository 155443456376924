import React, { Component } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

function FieldGroup({ id, label, ...props }) {
    return (
        <FormGroup controlId={id}>
            <ControlLabel>{label}</ControlLabel>
            <FormControl {...props} />
        </FormGroup>
    );
}

class AdviceTranslation extends Component {
    onTextChange = (event) => {
        this.props.onTextChange(event, this.props.lang);
    };

    render() {
        const props = this.props;
        const lang = this.props.lang;

        return (
            <div>
                <FieldGroup
                    id="AdviceText"
                    type="text"
                    label={'Consejo (' + lang + ')'}
                    placeholder="Escribe aquí el consejo..."
                    value={props.text}
                    onChange={this.onTextChange}
                    required
                />
                <FieldGroup
                    id="description"
                    type="text"
                    label={'Explicación (' + lang + ')'}
                    placeholder="..."
                    value={props.description}
                    onChange={props.onDescriptionChange}
                />
            </div>
        );
    }
}

export default AdviceTranslation;
