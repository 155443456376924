class AdviceSections {
    static GASTOS = '1';
    static INGRESOS = '2';
    static PERFIL = '3';
    static BLOG = '4';
    static INFORMACION = '5';
    static IMPUESTOS = '6';
    static RENTA = '7';

    data = [];

    constructor(data) {
        this.data = data;
    }

    getOptions() {
        let options = [];
        for (let i = 0; i < this.data.length; i++) {
            options[i] = {
                value: this.data[i].id,
                label: this.data[i].section,
            };
        }
        return options;
    }

    getOptionOfId(key) {
        let indexFound = null;
        for (let index = 0; index < this.data.length; index++) {
            if (this.data[index].id === key) indexFound = index;
        }
        let result =
            indexFound != null
                ? {
                      value: this.data[indexFound].id,
                      label: this.data[indexFound].section,
                  }
                : null;
        return result;
    }

    getLabelOfId(key) {
        let indexFound = null;
        for (let index = 0; index < this.data.length; index++) {
            if (this.data[index].id === key) indexFound = index;
        }
        let result = indexFound != null ? this.data[indexFound].section : null;

        return result;
    }
}

export default AdviceSections;
