import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import V2Repository from '../../repositories/V2Repository';
import LocationsRepository from '../../repositories/LocationsRepository';

class AuthenticatedRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
        };
        this.v2Repository = new V2Repository();
    }

    goLost = () => {
        this.locations = new LocationsRepository();
        const loginUri = this.locations.getLostUri();
        this.props.history.push(loginUri);
    };

    componentDidMount() {
        this.v2Repository
            .isAuthenticated()
            .then(() => {
                this.setState({
                    authenticated: true,
                });
            })
            .catch(() => {
                this.goLost();
            });
    }

    render() {
        if (!this.state.authenticated) {
            return <div>Loading...</div>;
        }

        return <Route path={this.props.path} component={this.props.component} />;
    }
}

export default withRouter(AuthenticatedRoute);
