import React, { Component } from 'react';

class FilterTab extends Component {
    render() {
        let selectStyle = this.props.selected
            ? {
                  cursor: 'pointer',
                  fontWeight: '500',
                  color: 'white',
                  background: '#3CA3F9',
                  padding: '5px 15px',
                  display: 'inline-block',
                  fontSize: '14px',
                  lineHeight: '14px',
                  borderRadius: '24px',
              }
            : {
                  cursor: 'pointer',
                  color: '#333',
                  fontWeight: '500',
                  padding: '5px 15px',
              };

        return (
            <span
                className={this.props.className}
                style={selectStyle}
                onClick={() => this.props.handleStatusFilterChange(this.props.filter)}
            >
                {this.props.title}
            </span>
        );
    }
}
export default FilterTab;
