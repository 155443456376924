import React, { Component } from 'react';
import { Button, ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import Planes from '../../components/ListsOptions/Planes';
import { Asterisk, AsyncSelect, InputContext, InputCurrency, InputMessage, InputNumber, SimpleSelect } from 'book';
import Datetime from 'react-datetime';
import Date from '../../assets/utils/Date';

class PlanChangeModal extends Component {
    render() {
        let classButton = this.props.isValid ? 'btn-primary' : '';

        let error = this.props.errorMessage ? (
            <InputMessage theme="danger">{this.props.errorMessage}</InputMessage>
        ) : (
            ''
        );

        let paymentType = '';

        switch (this.props.data.type) {
            case 'visa':
                paymentType = 'Contratación activa con VISA';
                break;
            case 'banco':
                paymentType = 'Contratación activa con BANCO';
                break;
            case '':
                paymentType = 'No tenemos registro de contratación. Who is this?';
                break;
            default:
        }

        const isFree = this.props.data.plan === Planes.FREE_KEY;

        return (
            <Modal id={this.props.modalId} show={this.props.modalOpen} onHide={this.props.toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error}
                    <form>
                        <div className={'form-group'}>
                            <ControlLabel>
                                Usuario <Asterisk />
                            </ControlLabel>
                            <AsyncSelect
                                id={'userId'}
                                promiseOptions={this.props.loadUsers}
                                onChange={this.props.handleUserChange}
                            />
                        </div>
                        <p className={'text-center'}>{paymentType}</p>
                        <div className={'form-group'}>
                            <ControlLabel>
                                Fecha del cambio <Asterisk />
                            </ControlLabel>
                            <Datetime
                                dateFormat={Date.visualFormat}
                                timeFormat={false}
                                value={this.props.data.date}
                                onChange={this.props.handleDateChange}
                            />
                        </div>
                        <div className={'form-group'}>
                            <ControlLabel>
                                Plan <Asterisk />
                            </ControlLabel>
                            <SimpleSelect
                                id="plan"
                                value={this.props.data.plan}
                                options={Planes.getOptions()}
                                onChange={this.props.handlePlanChange}
                            />
                        </div>
                        <FormGroup controlId={'price'}>
                            <ControlLabel>
                                Precio mensual <Asterisk />
                            </ControlLabel>
                            <InputCurrency
                                id={'precio'}
                                value={this.props.data.price}
                                onChange={this.props.handlePriceChange}
                                disabled={isFree}
                            />
                        </FormGroup>
                        <FormGroup controlId={'mensa'}>
                            <ControlLabel>
                                Periodo de cobro <Asterisk />
                            </ControlLabel>
                            <InputContext label={'mes/-es'}>
                                <InputNumber
                                    id={'mensualidades'}
                                    value={this.props.data.months}
                                    onChange={this.props.handleMonthsChange}
                                    min={0}
                                    max={12}
                                    disabled={isFree}
                                />
                            </InputContext>
                            <InputMessage theme={'danger'}>{this.props.errorMonths}</InputMessage>
                        </FormGroup>
                        <FormGroup controlId={'discount'}>
                            <ControlLabel>
                                Descuento <Asterisk />
                            </ControlLabel>
                            <InputContext label={'%'}>
                                <InputNumber
                                    id={'discount'}
                                    value={this.props.data.discount}
                                    onChange={this.props.handleDiscountChange}
                                    step={0.01}
                                    min={0}
                                    max={100}
                                    disabled={isFree}
                                    float={true}
                                />
                            </InputContext>
                        </FormGroup>
                        <div className={'form-group'}>
                            <ControlLabel>
                                Evento <Asterisk />
                            </ControlLabel>
                            <SimpleSelect
                                id={'event'}
                                value={this.props.data.event}
                                onChange={this.props.handleEventChange}
                                options={[
                                    {
                                        value: 'UPSELL',
                                        label: 'Upsell',
                                    },
                                    {
                                        value: 'DOWNSELL',
                                        label: 'Downsell',
                                    },
                                ]}
                            />
                        </div>
                        {isFree ? (
                            <div className={'form-group'}>
                                <ControlLabel>
                                    Motivo baja <Asterisk />
                                </ControlLabel>
                                <SimpleSelect
                                    id={'reason'}
                                    value={this.props.data.reason}
                                    onChange={this.props.handleReasonChange}
                                    options={[
                                        {
                                            value: 'NOT_FIT',
                                            label: 'Not Fit',
                                        },
                                        {
                                            value: 'IMPAGADO',
                                            label: 'Impagado',
                                        },
                                        {
                                            value: 'BAJA_AUTONOMO',
                                            label: 'Baja autonomo',
                                        },
                                        {
                                            value: 'DESCONTENTO',
                                            label: 'Descontento',
                                        },
                                        {
                                            value: 'OTROS',
                                            label: 'Otros',
                                        },
                                    ]}
                                />
                            </div>
                        ) : null}

                        <FormGroup id={'observations'}>
                            <ControlLabel>
                                Observaciones <Asterisk />
                            </ControlLabel>
                            <textarea
                                className={'form-control is-vertical'}
                                value={this.props.data.observations}
                                onChange={(e) => this.props.handleObservationsChange(e.target.value)}
                                id="observations"
                                rows="4"
                            />
                        </FormGroup>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={classButton} onClick={this.props.handleSubmit} disabled={!this.props.isValid}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PlanChangeModal;
