import React, { Component } from 'react';
import ModalConfirmacion from '../components/ModalConfirmacion';
import _ from 'lodash';
import { Col, Modal, Row } from 'react-bootstrap';
import { Content, ContentToolbelt, ContentContainer, ActionButton, Label, Input, LinkButton, Spinner } from 'book';
import UserV2Repository from "../repositories/UserV2Repository";

class UserRow extends Component {
    render() {
        let selected = '';
        if (this.props.selectedId === this.props.data.id) {
            selected = 'row-selected';
        }

        return (
            <tr className={selected}>
                <td>{this.props.data.id}</td>
                <td>{this.props.data.name}</td>
                <td>{this.props.data.username}</td>
                <td>{this.props.data.email}</td>
                <td>
                    <button
                        title={this.props.buttonTitle}
                        className={this.props.buttonClass}
                        onClick={() => this.props.buttonHandler(this.props.data.id)}
                    >
                        <i className={this.props.buttonIcon} />
                    </button>
                </td>
            </tr>
        );
    }
}

class AgentChildrenTable extends Component {
    render() {
        return (
            <div className={'table-responsive'}>
                <table
                    id={'agent-children-table'}
                    className={'table table-striped table-bordered table-condensed table-hover table-responsive'}
                >
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Usuario</th>
                            <th>Nombre</th>
                            <th>Email</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.rows.map((user, key) => {
                            return (
                                <UserRow
                                    data={user}
                                    key={key}
                                    selectedId={this.props.selectedId}
                                    buttonTitle={'Borrar usuario!'}
                                    buttonClass={'btn-remove-child'}
                                    buttonIcon={'fa fa-trash'}
                                    buttonHandler={() => this.props.actionHandler(user.id)}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

class AgentsTable extends Component {
    render() {
        return (
            <div className={'table-responsive'}>
                <table
                    id={'agents-table'}
                    className={'table table-striped table-bordered table-condensed table-hover table-responsive'}
                >
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Usuario</th>
                            <th>Nombre</th>
                            <th>Email</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.users.map((user, key) => {
                            let buttonTitle = 'Mostrar hijos';
                            let buttonIcon = 'fa fa-plus';
                            let buttonHandler = this.props.showChildrenHandler;
                            let agentChildren = null;

                            if (user.id === this.props.selectedUserId) {
                                buttonTitle = 'Esconder hijos';
                                buttonIcon = 'fa fa-minus';
                                buttonHandler = this.props.hideChildrenHandler;
                                agentChildren = (
                                    <tr key={user.id + '_2'}>
                                        <td colSpan={5}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    marginBottom: 30,
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <h4 style={{ margin: '0' }}>Usuarios Hijo</h4>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={this.props.openAddChildModalHandler}
                                                >
                                                    Añadir Hijo
                                                </button>
                                            </div>
                                            <AgentChildrenTable
                                                rows={this.props.loadedChildren}
                                                selectedId={this.props.deletingUserId}
                                                actionHandler={this.props.handleDeleteChildUser}
                                            />
                                        </td>
                                    </tr>
                                );
                            }

                            return [
                                <UserRow
                                    data={user}
                                    key={user.id}
                                    selectedId={this.props.selectedUserId}
                                    buttonClass={'btn-load-children'}
                                    buttonTitle={buttonTitle}
                                    buttonIcon={buttonIcon}
                                    buttonHandler={buttonHandler}
                                />,
                                agentChildren,
                            ];
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

class AgentModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            agentFound: false,
            agentDisabled: false,
            agentFilter: '',
            agentPresent: false,
            user: {
                name: '',
                email: '',
                id: null,
            },
        };
    }

    checkValidUser = () => {
        this.props.isAgentValid(this.state.agentFilter).then((response) => {
            const data = response.data;
            this.setState({
                user: {
                    name: data.name,
                    mail: data.mail,
                    id: data.id,
                },
                agentFound: data.valid,
                agentPresent: data.disabled,
            });
        });
    };

    inputChangeHandler = (e) => {
        this.setState(
            {
                agentFilter: e.target.value,
            },
            function () {
                this.checkValidUser();
            },
        );
    };

    closeModal = () => {
        this.setState({
            agentFilter: '',
            agentFound: false,
            agentDisabled: false,
            agentPresent: false,
            user: {
                name: '',
                email: '',
                id: null,
            },
        });
        this.props.closeModal();
    };

    handleSave = () => {
        const expertId = this.state.user.id;
        this.props.handleSave(expertId);
        this.setState({
            agentFilter: '',
            agentFound: false,
            agentDisabled: false,
            agentPresent: false,
            user: {
                name: '',
                email: '',
                id: null,
            },
        });
    };

    render() {
        let disabled = !this.state.agentFound || this.state.agentPresent;
        return (
            <Modal id={this.props.modalId} show={this.props.modalOpen} onHide={this.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <form className="form-inline">
                            <div className="col-xs-10 col-xs-offset-1">
                                <div className="form-group" style={{ display: 'block' }}>
                                    <label className="sr-only" for="addAgentInput">
                                        Escribe un ID o un email
                                    </label>
                                    <input
                                        value={this.state.agentFilter}
                                        onChange={(e) => this.inputChangeHandler(e)}
                                        style={{ width: '100%' }}
                                        type="text"
                                        className="form-control"
                                        id="addAgentInput"
                                        placeholder="ID/Email"
                                    />
                                </div>
                            </div>
                            <div className="col-xs-10 col-xs-offset-1" style={{ paddingBottom: '20px' }}>
                                {this.state.agentPresent ? (
                                    <h5
                                        style={{
                                            color: 'red',
                                            margin: 0,
                                            marginTop: 20,
                                        }}
                                    >
                                        Este usuario ya es agente o hijo de agente.
                                    </h5>
                                ) : (
                                    ''
                                )}
                                {this.state.agentFound ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: 15,
                                            border: '1px solid gainsboro',
                                            borderRadius: 4,
                                            marginTop: 20,
                                        }}
                                    >
                                        <div>
                                            <p>
                                                <strong>Nombre: </strong>
                                                {this.state.user.name}
                                            </p>
                                            <p>
                                                <strong>Email: </strong>
                                                {this.state.user.mail}
                                            </p>
                                            <p>
                                                <strong>ID: </strong>
                                                {this.state.user.id}
                                            </p>
                                        </div>
                                        <button
                                            onClick={this.handleSave}
                                            disabled={disabled}
                                            className={!disabled ? 'btn btn-primary' : 'btn btn-default disabled'}
                                        >
                                            Añadir
                                        </button>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

class Agencies extends Component {
    constructor(props) {
        super(props);
        this.userV2Repository = new UserV2Repository();

        this.reloadUsers = _.debounce(this.loadUsers, 300);

        this.state = {
            page: 0,
            pageRows: 10,
            filter: '',
            users: [],
            selectedUserId: null,
            deletingUserId: null,
            loadedChildren: [],
            modalConfirmOpen: false,
            modalAgentOpen: false,
            modalChildOpen: false,
            loadingUsers: false,
        };
    }

    loadUsers() {
        this.setState(() => ({ loadingUsers: true }));
        this.userV2Repository
            .getAgentsPagedWithFilter(this.state.page, this.state.pageRows, this.state.filter)
            .then((response) => {
                this.setState({
                    users: response.data,
                });
            })
            .finally(() => this.setState(() => ({ loadingUsers: false })));
    }

    loadAgentChildren() {
        this.userV2Repository.getAgentChildren(this.state.selectedUserId).then((response) => {
            this.setState({
                loadedChildren: response.data,
            });
        });
    }

    componentDidMount() {
        this.loadUsers();
    }

    handleFilterChange(e) {
        this.setState(
            {
                filter: e.target.value,
            },
            function () {
                this.reloadUsers();
            },
        );
    }

    handleNextPage() {
        let page = this.state.page;
        this.setState(
            {
                page: page + 1,
            },
            function () {
                this.loadUsers();
            },
        );
    }

    handlePreviousPage() {
        let page = this.state.page - 1;
        if (page < 0) page = 0;
        this.setState(
            {
                page: page,
            },
            function () {
                this.loadUsers();
            },
        );
    }

    showChildrenHandler(agentId) {
        this.setState(
            {
                selectedUserId: agentId,
            },
            function () {
                this.loadAgentChildren();
            },
        );
    }

    hideChildrenHandler() {
        this.setState({
            selectedUserId: null,
        });
    }

    handleDeleteChildUser(id) {
        this.setState({
            deletingUserId: id,
            modalConfirmOpen: true,
        });
    }

    handelCancelDelete() {
        this.setState({
            deletingUserId: null,
            modalConfirmOpen: false,
        });
    }

    handleSubmitDelete() {
        this.userV2Repository.removeAgentChild(this.state.deletingUserId).then((response) => {
            this.loadAgentChildren();
            this.setState({
                deletingUserId: null,
                modalConfirmOpen: false,
            });
        });
    }

    openAddAgentModalHandler = () => {
        this.setState({
            modalAgentOpen: true,
        });
    };

    openAddChildModalHandler = () => {
        this.setState({
            modalChildOpen: true,
        });
    };

    closeAddModalHandler = () => {
        this.setState({
            modalAgentOpen: false,
            modalChildOpen: false,
        });
    };

    handleSaveAgent = (id) => {
        this.userV2Repository.addExpert(id).then((response) => {
            if (response.success) {
                this.reloadUsers();
                this.closeAddModalHandler();
            }
        });
    };

    handleSaveChild = (childId) => {
        const expertId = this.state.selectedUserId;
        this.userV2Repository.addExpertChild(expertId, childId).then((response) => {
            if (response.success) {
                this.reloadUsers();
                this.loadAgentChildren();
                this.closeAddModalHandler();
            }
        });
    };

    isAgentValid = (filter) => {
        return this.userV2Repository.checkValidUserBy(filter);
    };

    render() {
        return (
            <>
                <Content id="agentes" title={'Gestión de Agentes'}>
                    <ContentToolbelt>
                        <Row>
                            <Col sm={12}>
                                <ActionButton
                                    title={'Añadir consejo'}
                                    small
                                    className={'pull-right font-weight-500'}
                                    onClick={this.openAddAgentModalHandler}
                                >
                                    Añadir agente
                                    <i className={'fa fa-briefcase ml-p5'} />
                                </ActionButton>
                            </Col>
                        </Row>
                    </ContentToolbelt>
                    <ContentContainer>
                        <Row>
                            <Col className={'mb-1'} xs={12} sm={3} md={4}>
                                <Label theme={'primary'}>Identificador</Label>
                                <Input
                                    type="text"
                                    name="filterAdvices"
                                    className={'mb-0'}
                                    placeholder="ID, Nombre, Usuario, Email, DNI"
                                    value={this.state.filter}
                                    onChange={this.handleFilterChange.bind(this)}
                                />
                            </Col>
                        </Row>
                        <Row className={'mt-4 mb-2'}>
                            <Col xs={12}>
                                {this.state.loadingUsers ? (
                                    <div
                                        style={{
                                            border: '1px solid gainsboro',
                                        }}
                                    >
                                        <Spinner onlyloader={false} />
                                    </div>
                                ) : this.state.users.length ? (
                                    <AgentsTable
                                        users={this.state.users}
                                        selectedUserId={this.state.selectedUserId}
                                        deletingUserId={this.state.deletingUserId}
                                        showChildrenHandler={this.showChildrenHandler.bind(this)}
                                        hideChildrenHandler={this.hideChildrenHandler.bind(this)}
                                        handleDeleteChildUser={this.handleDeleteChildUser.bind(this)}
                                        openAddChildModalHandler={this.openAddChildModalHandler}
                                        loadedChildren={this.state.loadedChildren}
                                    />
                                ) : (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: '1px solid #E3E3E3',
                                            height: '300px',
                                        }}
                                    >
                                        <span className="text-left d-inline-block">
                                            <p className="h4 font-weight-600 m-0">No se han encontrado resultados</p>
                                        </span>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <LinkButton
                                    id={'page-previous'}
                                    className={'pull-left font-weight-500'}
                                    onClick={this.handlePreviousPage.bind(this)}
                                >
                                    ⬅ Anterior
                                </LinkButton>
                                <LinkButton
                                    id={'page-next'}
                                    className={'pull-right font-weight-500'}
                                    onClick={this.handleNextPage.bind(this)}
                                >
                                    Siguiente ⮕
                                </LinkButton>
                            </Col>
                        </Row>
                    </ContentContainer>
                </Content>

                <AgentModal
                    modalId="addAgent"
                    modalTitle="Añadir Agente"
                    modalOpen={this.state.modalAgentOpen}
                    closeModal={this.closeAddModalHandler}
                    handleSave={this.handleSaveAgent}
                    isAgentValid={this.isAgentValid}
                />

                <AgentModal
                    modalId="addChild"
                    modalTitle="Añadir Hijo"
                    modalOpen={this.state.modalChildOpen}
                    closeModal={this.closeAddModalHandler}
                    handleSave={this.handleSaveChild}
                    isAgentValid={this.isAgentValid}
                />

                <ModalConfirmacion
                    id={'modal-reset'}
                    open={this.state.modalConfirmOpen}
                    title={'¿Estás segura?'}
                    text={'Vas a eliminar este usuario de forma completa. ¿Quieres continuar?'}
                    action={'Estoy completamente segura!'}
                    handleAction={this.handleSubmitDelete.bind(this)}
                    handleCancel={this.handelCancelDelete.bind(this)}
                />
            </>
        );
    }
}

export default Agencies;
