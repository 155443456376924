import {Icon} from "declarando";
import {MenuItem} from "react-bootstrap";
import React, {useRef} from "react";
import {ModalDescargarLibrosContables} from "./ModalDescargarLibrosContables";

export const DescargarLibrosContables = ({row = null, years}) => {
    const modalRef = useRef();
    const openModal = () => modalRef.current.openModal();
    return <>
        <MenuItem onClick={openModal}>
            <Icon name="book-open"/>
            Descargar libros contables
        </MenuItem>
        <ModalDescargarLibrosContables row={row} ref={modalRef} years={years}/>
    </>
}

