import React, { Component } from 'react';
import { Modal, Button, Row, Col, Table, Alert, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import _ from 'lodash';
import ProductsRepository from '../repositories/ProductRepository';
import ProductTypes from '../components/ListsOptions/ProductTypes';
import {
    ActionButton,
    Content,
    ContentContainer,
    ContentToolbelt,
    FileUploader,
    Input,
    Label,
    LinkButton,
    Spinner,
} from 'book';
import FileRepository from '../repositories/FileRepository';

function FieldGroup({ id, label, ...props }) {
    return (
        <FormGroup controlId={id}>
            <ControlLabel>{label}</ControlLabel>
            <FormControl {...props} />
        </FormGroup>
    );
}

class TableAdviserProducts extends Component {
    render() {
        return (
            <Table striped bordered condensed hover responsive>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Tipo</th>
                        <th>Precio €</th>
                        <th>Link</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.products.map((product, key) => {
                        return (
                            <tr key={key}>
                                <td>{product.name}</td>
                                <td>{product.type.name}</td>
                                <td>{product.price}</td>
                                <td>{product.link}</td>
                                <td>
                                    <Button id={'edit_' + product.id} onClick={() => this.props.handleEdition(product)}>
                                        <i className={'fa fa-pencil-square-o'}></i>
                                    </Button>
                                    &nbsp;
                                    <Button
                                        id={'delete_' + product.id}
                                        onClick={() => this.props.handleDelete(product.id)}
                                    >
                                        <i className={'fa fa-trash-o'}></i>
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    }
}

class ProductModal extends Component {
    render() {
        let classButton = this.props.isValid ? 'btn-primary' : '';

        let error = this.props.errorMessage ? <Alert bsStyle="danger">{this.props.errorMessage}</Alert> : '';

        const typeOptions = new ProductTypes();

        return (
            <Modal id={this.props.modalId} show={this.props.modalOpen} onHide={this.props.toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error}
                    <form>
                        <FieldGroup
                            id="ProductName"
                            type="text"
                            label="Nombre"
                            placeholder="Escribe aquí el nombre..."
                            value={this.props.product.name}
                            onChange={this.props.handleNameChange}
                            required
                        />
                        {typeOptions.data.length > 0 ? (
                            <div className={'form-group'}>
                                <ControlLabel>Tipo</ControlLabel>
                                <Select
                                    id="type"
                                    value={typeOptions.getOptionOfId(this.props.product.type.id)}
                                    options={typeOptions.getOptions()}
                                    onChange={(e) => this.props.handleTypeChange(e.value, e.label)}
                                />
                            </div>
                        ) : null}
                        <FieldGroup
                            id="ProductInformation"
                            type="text"
                            label="Información"
                            placeholder="Escribe aquí la explicación..."
                            value={this.props.product.information}
                            onChange={this.props.handleInformationChange}
                            required
                        />
                        <FieldGroup
                            id="ProductPrice"
                            label="Precio"
                            value={this.props.product.price}
                            onChange={this.props.handlePriceChange}
                            required
                        />
                        <FieldGroup
                            id="ProductLink"
                            label="Link"
                            placeholder="https://www.declarando.es"
                            value={this.props.product.link}
                            onChange={this.props.handleLinkChange}
                            required
                        />
                        <FileUploader
                            file={this.props.product.file}
                            fileKey={'productLogo'}
                            waitForTriggerStore={true}
                            ref={this.props.uploaderRef}
                            handleStore={(formData) => {
                                this.props.repository.postProductLogo(this.props.product.id, formData).then(() => {
                                    this.props.updateFile(formData.get('productLogo').name);
                                });
                            }}
                            handleGet={() => this.props.repository.getProductLogoUrl(this.props.product.id)}
                            handleDelete={() => {
                                return this.props.repository
                                    .deleteProductLogo(this.props.product.id)
                                    .then(this.props.clearFile);
                            }}
                            fileTypes={'.jpg'}
                        />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={classButton} onClick={this.props.handleSubmit} disabled={!this.props.isValid}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

class Products extends Component {
    constructor(props) {
        super(props);

        this.productRepository = new ProductsRepository();
        this.repository = new FileRepository();

        this.reloadProducts = _.debounce(this.loadProducts, 300);
        this.uploaderRef = null;

        this.state = {
            page: 0,
            pageRows: 10,
            addProductModalOpen: false,
            editProductModalOpen: false,
            confirmProductModalOpen: false,
            deleteProductId: null,
            products: [],
            product: {
                id: null,
                name: '',
                type: {
                    id: null,
                    name: null,
                },
                information: '',
                price: '',
                link: '',
                file: {
                    filename: '',
                    valid: false,
                },
            },
            types: [],

            errorMessage: '',
            productsFilter: '',
            isValidForm: false,
            loadingProducts: false,
        };
    }

    componentDidMount() {
        this.loadProducts();
    }

    loadProducts = () => {
        this.setState(() => ({ loadingProducts: true }));
        this.productRepository
            .getProducts(this.state.page, this.state.pageRows, this.state.productsFilter)
            .then((response) => {
                if (response.success) {
                    this.setState({
                        products: response.data,
                    });
                }
            })
            .finally(() => this.setState(() => ({ loadingProducts: false })));
    };

    storeFile = () => {
        this.uploaderRef.triggerStore();
    };

    clearFile = () => {
        this.setState({
            product: {
                ...this.state.product,
                file: {
                    valid: false,
                    filename: '',
                },
            },
        });
    };

    updateFile = (name) => {
        let filename = name;
        let valid = true;

        this.setState(
            {
                product: {
                    ...this.state.product,
                    file: {
                        valid: valid,
                        filename: filename,
                    },
                },
            },
            this.checkValidForm,
        );
    };

    handleNextPage() {
        let page = this.state.page;
        this.setState(
            {
                page: page + 1,
            },
            this.loadProducts,
        );
    }

    handlePreviousPage() {
        let page = this.state.page - 1;
        if (page < 0) page = 0;
        this.setState(
            {
                page: page,
            },
            this.loadProducts,
        );
    }

    resetProduct() {
        this.setState({
            product: {
                id: null,
                name: '',
                type: {
                    id: null,
                    name: null,
                },
                information: '',
                price: '',
                link: '',
                file: {
                    filename: '',
                    valid: false,
                },
            },
            isValidForm: false,
            errorMessage: '',
        });
    }

    handleProductsFilterChange = (event) => {
        this.setState(
            {
                productsFilter: event.target.value,
            },
            this.reloadProducts,
        );
    };

    handleNameChange = (event) => {
        this.setState(
            {
                product: { ...this.state.product, name: event.target.value },
            },
            this.checkValidForm,
        );
    };

    handleTypeChange = (value, label) => {
        this.setState(
            {
                product: {
                    ...this.state.product,
                    type: {
                        id: value,
                        name: label,
                    },
                },
            },
            this.checkValidForm,
        );
    };

    handleInformationChange = (event) => {
        this.setState(
            {
                product: { ...this.state.product, information: event.target.value },
            },
            this.checkValidForm,
        );
    };

    handlePriceChange = (event) => {
        this.setState(
            {
                product: { ...this.state.product, price: event.target.value },
            },
            this.checkValidForm,
        );
    };

    handleLinkChange = (event) => {
        this.setState(
            {
                product: { ...this.state.product, link: event.target.value },
            },
            this.checkValidForm,
        );
    };

    checkValidForm() {
        let valid = this.isValidForm();
        this.setState({
            isValidForm: valid,
        });
    }

    isValidForm() {
        return (
            this.state.product.name &&
            this.state.product.type &&
            this.state.product.information &&
            this.state.product.price &&
            this.state.product.link
        );
    }

    handleAddition = () => {
        this.resetProduct();
        this.setState({
            addProductModalOpen: true,
        });
    };

    handleSaveClick = () => {
        this.productRepository.saveProduct(this.state.product).then((response) => {
            if (!this.state.product.id) {
                this.setState(
                    {
                        product: { ...this.state.product, id: response.data },
                    },
                    this.storeFile,
                );
                this.closeAddProductModal();
            } else {
                this.storeFile();
                this.closeEditProductModal();
            }
            this.loadProducts();
        });
    };

    closeAddProductModal = () => {
        this.setState({
            addProductModalOpen: false,
        });
    };

    handleEdition = (product) => {
        this.setState(
            {
                editProductModalOpen: true,
                product: product,
            },
            this.checkValidForm,
        );
    };

    closeEditProductModal = () => {
        this.setState({
            editProductModalOpen: false,
        });
    };

    handleDelete = (productId) => {
        this.setState({
            deleteProductId: productId,
            confirmDeleteModalOpen: true,
        });
    };

    toggleConfirmDeleteModal = () => {
        this.setState({
            confirmDeleteModalOpen: !this.state.confirmDeleteModalOpen,
        });
    };

    deleteProduct = () => {
        this.productRepository.deleteProduct(this.state.deleteProductId).then((response) => {
            if (response.success) {
                this.toggleConfirmDeleteModal();
                this.resetProduct();
                this.loadProducts();
            } else {
                this.setState({
                    errorMessage: response.message,
                });
            }
        });
    };

    render() {
        return (
            <>
                <Content id="productos" title={'Listado de Productos'}>
                    <ContentToolbelt>
                        <Row>
                            <Col sm={12}>
                                <ActionButton
                                    title={'Añadir consejo'}
                                    small
                                    className={'pull-right font-weight-500'}
                                    onClick={this.handleAddition}
                                >
                                    Añadir producto
                                    <i className={'fa fa-product-hunt ml-p5'} />
                                </ActionButton>
                            </Col>
                        </Row>
                    </ContentToolbelt>
                    <ContentContainer>
                        <Row>
                            <Col className={'mb-1'} xs={12} sm={3} md={4}>
                                <Label theme={'primary'}>Filtrar por contenido</Label>
                                <Input
                                    type="text"
                                    name="filterContent"
                                    className={'mb-0'}
                                    placeholder="Escribe algo..."
                                    value={this.state.productsFilter}
                                    onChange={this.handleProductsFilterChange}
                                />
                            </Col>
                        </Row>
                        <Row className={'mt-4 mb-2'}>
                            <Col xs={12}>
                                {this.state.loadingProducts ? (
                                    <div
                                        style={{
                                            border: '1px solid gainsboro',
                                        }}
                                    >
                                        <Spinner onlyloader={false} />
                                    </div>
                                ) : this.state.products.length ? (
                                    <TableAdviserProducts
                                        products={this.state.products}
                                        handleEdition={this.handleEdition}
                                        handleDelete={this.handleDelete}
                                        toggleStatus={this.toggleStatus}
                                    />
                                ) : (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: '1px solid #E3E3E3',
                                            height: '300px',
                                        }}
                                    >
                                        <span className="text-left d-inline-block">
                                            <p className="h4 font-weight-600 m-0">No se han encontrado resultados</p>
                                        </span>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <LinkButton
                                    id={'page-previous'}
                                    className={'pull-left font-weight-500'}
                                    onClick={this.handlePreviousPage.bind(this)}
                                >
                                    ⬅ Anterior
                                </LinkButton>
                                <LinkButton
                                    id={'page-next'}
                                    className={'pull-right font-weight-500'}
                                    onClick={this.handleNextPage.bind(this)}
                                >
                                    Siguiente ⮕
                                </LinkButton>
                            </Col>
                        </Row>
                    </ContentContainer>
                </Content>
                <ProductModal
                    modalId="add-Product-modal"
                    title="Nuevo Producto"
                    errorMessage={this.state.errorMessage}
                    handleNameChange={this.handleNameChange}
                    handleTypeChange={this.handleTypeChange}
                    handleInformationChange={this.handleInformationChange}
                    handlePriceChange={this.handlePriceChange}
                    handleLinkChange={this.handleLinkChange}
                    isValid={this.state.isValidForm}
                    modalOpen={this.state.addProductModalOpen}
                    toggleModal={this.closeAddProductModal}
                    handleSubmit={this.handleSaveClick}
                    product={this.state.product}
                    types={this.state.types}
                    repository={this.repository}
                    updateFile={this.updateFile}
                    clearFile={this.clearFile}
                    uploaderRef={(ref) => {
                        this.uploaderRef = ref;
                    }}
                />
                <ProductModal
                    modalId="edit-Product-modal"
                    title="Modificar Producto"
                    errorMessage={this.state.errorMessage}
                    handleNameChange={this.handleNameChange}
                    handleTypeChange={this.handleTypeChange}
                    handleInformationChange={this.handleInformationChange}
                    handlePriceChange={this.handlePriceChange}
                    handleLinkChange={this.handleLinkChange}
                    isValid={this.state.isValidForm}
                    modalOpen={this.state.editProductModalOpen}
                    toggleModal={this.closeEditProductModal}
                    handleSubmit={this.handleSaveClick}
                    product={this.state.product}
                    types={this.state.types}
                    repository={this.repository}
                    updateFile={this.updateFile}
                    clearFile={this.clearFile}
                    uploaderRef={(ref) => {
                        this.uploaderRef = ref;
                    }}
                />

                <Modal id="delete-modal" show={this.state.confirmDeleteModalOpen}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar borrado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>¿Estás seguro de eliminar el producto?</Modal.Body>
                    <Modal.Footer>
                        <Button id="delete-button" className={'btn-danger'} onClick={this.deleteProduct}>
                            Eliminar
                        </Button>
                        <Button onClick={this.toggleConfirmDeleteModal}>Cancelar</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default Products;
