import React, { Component } from 'react';
import logoMini from '../assets/img/logo_declarando_N.svg';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

const Wrapper = styled.div`
    &:after {
        background: linear-gradient(180deg, #a9597e, #545c89);
    }
`;

const TextLogoBig = styled.a`
    text-decoration: none !important;
    font-size: 15px !important;
    line-height: 15px !important;
`;

const TextLogoSmall = styled.a`
    text-decoration: none !important;
    font-size: 10px !important;
    line-height: 10px !important;
`;

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.activeRoute = this.activeRoute.bind(this);

        this.state = {
            isMenuOpen: false,
        };
    }

    menuBtnClickHandler = () => {
        const nextState = !this.state.isMenuOpen;

        this.setState({
            isMenuOpen: nextState,
        });
        document.body.classList.toggle('sidebar-mini');
    };

    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
    }

    render() {
        const menuStatus = this.state.isMenuOpen
            ? 'btn btn-menu d-lg-block d-none align-self-center'
            : 'btn btn-menu d-lg-block d-none align-self-center is-closed';

        return (
            <Wrapper id="sidebar" className="sidebar">
                <div className="logo d-lg-flex align-items-lg-center mt-3 mb-0 d-none">
                    <a href={'/'} className="simple-text logo-mini">
                        <div className="logo-img">
                            <img src={logoMini} alt="Logo declarando" />
                        </div>
                    </a>
                    <div>
                        <TextLogoBig href={'/'} className="simple-text logo-normal">
                            declarando
                        </TextLogoBig>
                        <TextLogoSmall href={'/'} className="simple-text logo-normal">
                            administración
                        </TextLogoSmall>
                    </div>
                </div>
                <div className="sidebar-wrapper">
                    <ul className="nav">
                        {
                            // TODO: Refactor map into forEach
                            // eslint-disable-next-line
                            this.props.routes.map((prop, key) => {
                                if (!prop.redirect && !prop.hidden)
                                    return (
                                        <li className={`m-0 ${this.activeRoute(prop.path)}`} key={key}>
                                            <NavLink to={prop.path} className="nav-link" activeClassName="active">
                                                <i className={prop.icon} />
                                                <p>{prop.name}</p>
                                            </NavLink>
                                        </li>
                                    );
                            })
                        }
                    </ul>
                    <button onClick={this.menuBtnClickHandler} className={menuStatus}></button>
                </div>
            </Wrapper>
        );
    }
}

export default Sidebar;
