class Planes {
    static FREE = 'Free';
    static ESENCIAL = 'Esencial';
    static AVANZADO = 'Avanzado';
    static ESTELAR = 'Estelar';
    static BAJA = 'Bajas';

    static FREE_KEY = '1';
    static ESENCIAL_KEY = '2';
    static AVANZADO_KEY = '3';
    static ESTELAR_KEY = '4';
    static BAJA_KEY = '9';

    static data = {
        [Planes.ALL_KEY]: Planes.ALL,
        [Planes.FREE_KEY]: Planes.FREE,
        [Planes.ESENCIAL_KEY]: Planes.ESENCIAL,
        [Planes.AVANZADO_KEY]: Planes.AVANZADO,
        [Planes.ESTELAR_KEY]: Planes.ESTELAR,
        [Planes.BAJA_KEY]: Planes.BAJA,
    };

    static getFullOptions = () => {
        return [Planes.getOptionAll()].concat(Planes.getOptions());
    };

    static getOptions = () => {
        return [
            Planes.getOptionOfKey(Planes.FREE_KEY),
            Planes.getOptionOfKey(Planes.ESENCIAL_KEY),
            Planes.getOptionOfKey(Planes.AVANZADO_KEY),
            Planes.getOptionOfKey(Planes.ESTELAR_KEY),
            Planes.getOptionOfKey(Planes.BAJA_KEY),
        ];
    };

    static getOptionAll = () => {
        return Planes.getOptionOfKey(Planes.ALL_KEY);
    };

    static getOptionOfKey(value) {
        return {
            value: value,
            label: Planes.getLabelOf(value),
        };
    }

    static getLabelOf(key) {
        return Planes.data[key];
    }
}

export default Planes;
