import React, { Component } from 'react';
import { ServerSideTable, Content, ContentContainer } from 'book';
import PromocodesRepository from '../repositories/PromocodesRepository';

class Promocodes extends Component {
    constructor(props) {
        super(props);

        this.promocodesRepository = new PromocodesRepository();
    }

    renderPromoVariable = (data) => {
        if (data === 0) {
            return <em style={{ color: '#c3c3c3' }}>Sin variación</em>;
        }
        return data;
    };

    render() {
        return (
            <Content id={'promocodes'} title={'Listado de Promocodes'}>
                <ContentContainer>
                    <ServerSideTable
                        id={'promocodes-table'}
                        handleGet={(page, rows, filters, orders) => {
                            return this.promocodesRepository.getPromocodesPaged(page, rows);
                        }}
                        orderable={false}
                        filters={[]}
                        cols={[
                            {
                                name: 'Código',
                                data: 'code',
                            },
                            {
                                name: 'Meses pago inicial',
                                data: 'initial_months',
                                handler: (col, row, data) => {
                                    return this.renderPromoVariable(data);
                                },
                            },
                            {
                                name: 'Meses pago recurrente',
                                data: 'recurrent_months',
                                handler: (col, row, data) => {
                                    return this.renderPromoVariable(data);
                                },
                            },
                            {
                                name: 'Variación precio inicial €',
                                data: 'initial_price_variation',
                                handler: (col, row, data) => {
                                    return this.renderPromoVariable(data);
                                },
                            },
                            {
                                name: 'Variación precio recurrente €',
                                data: 'recurrent_price_variation',
                                handler: (col, row, data) => {
                                    return this.renderPromoVariable(data);
                                },
                            },
                            {
                                name: 'Descuento inicial %',
                                data: 'initial_discount',
                                handler: (col, row, data) => {
                                    return this.renderPromoVariable(data);
                                },
                            },
                            {
                                name: 'Descuento recurrente %',
                                data: 'recurrent_discount',
                                handler: (col, row, data) => {
                                    return this.renderPromoVariable(data);
                                },
                            },
                        ]}
                    />
                </ContentContainer>
            </Content>
        );
    }
}

export default Promocodes;
