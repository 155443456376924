import React, { useEffect } from "react";
import {MenuItem} from "react-bootstrap";
import { ModalDescargarInformeRenta } from "./ModalDescargarInformeRenta";
import UserV2Repository from "../../../../repositories/UserV2Repository";


export const useDescargarInformeRenta = ({userId}) => {

    const [show, setOpenModal] = React.useState(false);
    const [info, setInfo] = React.useState(null);
    const getPuedeDescargarInformeRenta = async () => { 
        const {tieneQuePresentar} = await new UserV2Repository().getInfoInformeRenta(userId).catch((error) => {
            alert('Endpoint error: ' + error.message)
        });
        setInfo(tieneQuePresentar);
    }
    useEffect(() => {
        if(show) {
            getPuedeDescargarInformeRenta();
        } else {
            setInfo(null);
        }
    // eslint-disable-next-line
    }, [show]);
    
    
    

    return {
        info,
        show,
        openModal: () => {
            setOpenModal(true);
        },
        closeModal: () => {
            setOpenModal(false);
        }
    }
}
export const DescargarInformeRenta = ({row}) => {
    const {show, openModal, closeModal, info} = useDescargarInformeRenta({userId: row.id});
    
    return <>
        <MenuItem onClick={openModal}>
        <i className={'fa fa-file-text'} />{' '}
            Descargar informe renta
        </MenuItem>
        <ModalDescargarInformeRenta userId={row.id} show={show} closeModal={closeModal} info={info}/>
    </>
}