import AdminV2Repository from "./AdminV2Repository";

export default class ProductsRepository extends AdminV2Repository {
    getPromocodesPaged(page, rows) {
        return this.post(
            '/promocodes',
            JSON.stringify({
                page,
                rows,
            }),
        );
    }
}
