import React, { Component } from 'react';
import ChartistGraph from 'react-chartist';
import { Row, Col } from 'react-bootstrap';
import DashboardRepository from '../repositories/DashboardRepository';
import HomeCard from '../components/Card/HomeCard';
import Card from '../components/Card/Card';

const MONTHS = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.dashboardRepository = new DashboardRepository();

        this.currentYear = new Date().getFullYear();
        this.currentMonth = new Date().getMonth();

        this.state = {
            usuarios: 0,
            clientes: 0,
            beneficio: 0,
            ahorro: 0,
            porcentajeUsuarios: 0,
            porcentajeClientes: 0,
            usuariosGraph: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            clientesGraph: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        this.dashboardRepository.getDashboardData().then((response) => {
            if (response.success) {
                const d = response.data;
                this.setState({
                    usuarios: d.usuarios,
                    clientes: d.clientes,
                    beneficio: d.beneficio,
                    ahorro: d.ahorro,
                    porcentajeUsuarios: d.porcentajeUsuarios,
                    porcentajeClientes: d.porcentajeClientes,
                    usuariosGraph: d.usuariosGraph,
                    clientesGraph: d.clientesGraph,
                });
            }
        });
    };

    render() {
        return (
            <div className={'main-content mt-4 mb-4'}>
                <Row className="show-grid">
                    <Col sm={3}>
                        <HomeCard
                            id={'users'}
                            bigIcon={'fa-plus'}
                            text={'Usuarios'}
                            porcentaje={this.state.porcentajeUsuarios}
                            value={this.state.usuarios}
                        />
                    </Col>
                    <Col sm={3}>
                        <HomeCard
                            id={'clientes'}
                            bigIcon={'fa-line-chart'}
                            text={'Clientes'}
                            porcentaje={this.state.porcentajeClientes}
                            value={this.state.clientes}
                        />
                    </Col>
                    <Col sm={3}>
                        <HomeCard
                            id={'beneficio'}
                            bigIcon={'fa-usd'}
                            text={'Beneficio'}
                            value={this.state.beneficio}
                            symbol={' €'}
                        />
                    </Col>
                    <Col sm={3}>
                        <HomeCard
                            id={'ahorro'}
                            bigIcon={'fa-question'}
                            text={'Ahorro'}
                            value={this.state.ahorro}
                            symbol={' €'}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Card
                            content={
                                <div>
                                    <h3 className="text-center">
                                        Crecimiento de usuarios <small>de {this.currentYear}</small>
                                    </h3>
                                    <ChartistGraph
                                        data={{
                                            labels: MONTHS,
                                            series: [this.state.usuariosGraph],
                                        }}
                                        options={this.getChartOptions(
                                            this.state.usuariosGraph[0],
                                            parseInt(this.state.usuariosGraph[this.currentMonth]) * 2,
                                        )}
                                        type="Line"
                                    />
                                </div>
                            }
                        />
                    </Col>
                    <Col sm={6}>
                        <Card
                            content={
                                <div>
                                    <h3 className="text-center">
                                        Crecimiento de clientes <small>de {this.currentYear}</small>
                                    </h3>
                                    <ChartistGraph
                                        data={{
                                            labels: MONTHS,
                                            series: [this.state.clientesGraph],
                                        }}
                                        options={this.getChartOptions(
                                            this.state.clientesGraph[0],
                                            parseInt(this.state.clientesGraph[this.currentMonth]) * 2,
                                        )}
                                        type="Line"
                                    />
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    getChartOptions = (low, high) => {
        return {
            low: low,
            high: high,
            showArea: false,
            axisX: {
                showGrid: true,
                showLabel: true,
            },
            axisY: {
                showGrid: true,
            },
            lineSmooth: true,
            showLine: true,
            showPoint: true,
        };
    };
}

export default Dashboard;
