import React, { Component } from 'react';
import { Alert, Button, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import AdviceSections from '../../components/ListsOptions/AdviceSections';
import AdviceProduct from '../../components/ListsOptions/AdviceProduct';
import Agents from '../../components/ListsOptions/Agents';
import Select from 'react-select';
import AdviceTranslation from './AdviceTranslation';
import AdvicesRepository from '../../repositories/AdvicesRepository';
import { locales } from '../../utils/locales';

function FieldGroup({ id, label, ...props }) {
    return (
        <FormGroup controlId={id}>
            <ControlLabel>{label}</ControlLabel>
            <FormControl {...props} />
        </FormGroup>
    );
}

class AdviceModal extends Component {
    constructor(props) {
        super(props);

        this.adviceRepository = new AdvicesRepository();

        this.state = {
            advice: {
                id: null,
                textList: [
                    {
                        lang: locales.ES,
                        text: '',
                        description: '',
                    },
                    {
                        lang: locales.EN,
                        text: '',
                        description: '',
                    },
                ],
                description: '',
                section: {
                    id: null,
                    name: null,
                },
                agent: {
                    id: null,
                    name: null,
                },
                username: '',
                link: '',
                triggers: '',
                status: true,
                isProduct: '0',
            },
            errorMessage: '',
            isValidForm: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.adviceId !== this.props.adviceId) {
            if (this.props.adviceId !== null) {
                this.loadAdvice(this.props.adviceId);
            } else {
                this.resetAdvice();
            }
        }
    }

    resetAdvice() {
        this.setState({
            advice: {
                id: null,
                textList: [
                    {
                        lang: locales.ES,
                        text: '',
                        description: '',
                    },
                    {
                        lang: locales.EN,
                        text: '',
                        description: '',
                    },
                ],
                description: '',
                section: {
                    id: null,
                    name: null,
                },
                agent: {
                    id: null,
                    name: null,
                },
                username: '',
                link: '',
                triggers: '',
                status: true,
                isProduct: '0',
            },
            errorMessage: '',
            isValidForm: false,
        });
    }

    loadAdvice = (adviceId) => {
        this.adviceRepository.getAdvice(adviceId).then((response) => {
            if (response.success) {
                this.setState(
                    {
                        advice: response.data,
                    },
                    this.checkValidForm,
                );
            }
        });
    };

    handleAdviceTextChange = (event, index) => {
        let textList = this.state.advice.textList;
        textList[index].text = event.target.value;

        this.setState(
            {
                advice: {
                    ...this.state.advice,
                    textList: textList,
                },
            },
            this.checkValidForm,
        );
    };

    handleDescriptionChange = (event, index) => {
        let textList = this.state.advice.textList;
        textList[index].description = event.target.value;

        this.setState(
            {
                advice: {
                    ...this.state.advice,
                    textList: textList,
                },
            },
            this.checkValidForm,
        );
    };

    handleSectionChange = (value, name) => {
        const productOption = value !== AdviceSections.RENTA ? AdviceProduct.CONSEJO : AdviceProduct.PRODUCTO;

        this.setState(
            {
                advice: {
                    ...this.state.advice,
                    section: {
                        id: value,
                        name: name,
                    },
                    isProduct: productOption,
                },
            },
            this.checkValidForm,
        );
    };

    handleProductChange = (value) => {
        this.setState(
            {
                advice: { ...this.state.advice, isProduct: value },
            },
            this.checkValidForm,
        );
    };

    handleAgentChange = (value, name) => {
        this.setState(
            {
                advice: {
                    ...this.state.advice,
                    agent: {
                        id: value,
                        name: name,
                    },
                },
            },
            this.checkValidForm,
        );
    };

    handleUsernameChange = (event) => {
        this.setState(
            {
                advice: { ...this.state.advice, username: event.target.value },
            },
            this.checkValidForm,
        );
    };

    handleLinkChange = (event) => {
        this.setState(
            {
                advice: { ...this.state.advice, link: event.target.value },
            },
            this.checkValidForm,
        );
    };

    checkValidForm() {
        let valid = this.isValidForm();
        this.setState({
            isValidForm: valid,
        });
    }

    isValidForm() {
        let translationValid = true;

        translationValid =
            translationValid &&
            this.state.advice.textList.map((current, index) => {
                let valid = true;
                if (current.lang === 'es') {
                    valid = current.text !== '' && current.text !== null;
                }

                return valid;
            });

        return translationValid && this.state.advice.section.name && this.state.advice.agent.name;
    }

    handleSubmit = () => {
        const advice = this.state.advice;
        this.resetAdvice();

        advice.textList = advice.textList.map((current, index) => {
            if (current.text === '' && advice.textList[0].text !== '') {
                current.text = advice.textList[0].text;
            }
            if (current.description === '' && advice.textList[0].description !== '') {
                current.description = advice.textList[0].description;
            }
            return current;
        });

        this.props.handleSubmit(advice);
    };

    render() {
        let classButton = this.props.isValid ? 'btn-primary' : '';

        let error = this.props.errorMessage ? <Alert bsStyle="danger">{this.props.errorMessage}</Alert> : '';

        const sectionOptions = new AdviceSections(this.props.sections);
        const productOptions = new AdviceProduct();
        const agentsOptions = new Agents(this.props.agents);

        return (
            <Modal id={this.props.modalId} show={this.props.modalOpen} onHide={this.props.toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error}
                    <form>
                        <div className={'form-group'}>
                            <ControlLabel>Asesor</ControlLabel>
                            <Select
                                id="agent"
                                value={agentsOptions.getOptionOfId(this.state.advice.agent.id)}
                                options={agentsOptions.getOptions()}
                                onChange={(option) => {
                                    this.handleAgentChange(option.value, option.label);
                                }}
                            />
                        </div>
                        {this.state.advice.textList.map(function (adviceTexts, index) {
                            return (
                                <AdviceTranslation
                                    text={adviceTexts.text}
                                    description={adviceTexts.description}
                                    onTextChange={(e) => {
                                        this.handleAdviceTextChange(e, index);
                                    }}
                                    onDescriptionChange={(e) => {
                                        this.handleDescriptionChange(e, index);
                                    }}
                                    lang={adviceTexts.lang}
                                />
                            );
                        }, this)}
                        {sectionOptions.data.length > 0 ? (
                            <div className={'form-group'}>
                                <ControlLabel>Seccion</ControlLabel>
                                <Select
                                    id="section"
                                    value={sectionOptions.getOptionOfId(this.state.advice.section.id)}
                                    options={sectionOptions.getOptions()}
                                    onChange={(option) => {
                                        this.handleSectionChange(
                                            option.value,
                                            sectionOptions.getLabelOfId(option.value),
                                        );
                                    }}
                                />
                            </div>
                        ) : null}
                        {this.state.advice.section.id === AdviceSections.RENTA ? (
                            <div className={'form-group'}>
                                <ControlLabel>¿Tiene producto asociado?</ControlLabel>
                                <Select
                                    id="isProduct"
                                    value={productOptions.getOptionOfId(this.state.advice.isProduct)}
                                    options={productOptions.getOptions()}
                                    onChange={(e) => this.handleProductChange(e.value)}
                                />
                            </div>
                        ) : null}
                        <FieldGroup
                            id="username"
                            label="Usuario"
                            value={this.state.advice.username}
                            onChange={this.handleUsernameChange}
                        />
                        <FieldGroup
                            id="link"
                            label="Enlace"
                            value={this.state.advice.link}
                            onChange={this.handleLinkChange}
                        />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={classButton} onClick={this.handleSubmit} disabled={!this.state.isValidForm}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AdviceModal;
