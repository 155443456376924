import Dashboard from '../views/Dashboard';
import Advices from '../views/Advices/Advices';
import Users from '../views/Users';
import Agencies from '../views/Agents';
import Invitations from '../views/Invitations';
import Products from '../views/Products';
import Orders from '../views/Orders';
import RecurrentVisa from '../views/RecurrentVisa';
import PlansChange from '../views/PlansChange/PlansChange';
import Promocodes from '../views/Promocodes';

const appRoutes = [
    { name: 'Inicio', path: '/dashboard', icon: 'fa fa-home', component: Dashboard },
    { name: 'Gestión de Usuarios', path: '/gestion-de-usuarios', icon: 'fa fa-user', component: Users },
    { name: 'Gestión de Cobros', path: '/cobros', icon: 'fa fa-euro', component: Orders },
    { name: 'Gestión de Consejos', path: '/consejos-asesor', icon: 'fa fa-info-circle', component: Advices },
    { name: 'Gestión de Agentes', path: '/agents', icon: 'fa fa-briefcase', component: Agencies },
    { name: 'Invitaciones', path: '/invitaciones', icon: 'fa fa-heart', component: Invitations },
    { name: 'Productos', path: '/productos', icon: 'fa fa-product-hunt', component: Products },
    {
        name: 'Cambios de planes',
        path: '/changes-plans',
        icon: 'fa fa-calendar',
        component: PlansChange,
        id: 'cambios',
    },
    { name: 'Códigos de promoción', path: '/promocodes', icon: 'fa fa-euro', component: Promocodes, id: 'promos' },
    {
        name: 'Recurrencias VISA',
        path: '/recurrent-visa',
        icon: 'fa fa-cc-visa',
        component: RecurrentVisa,
        hidden: true,
    },
    { redirect: true, path: '/', to: '/dashboard', name: 'Inicio' },
];

export default appRoutes;
