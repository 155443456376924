import React, {forwardRef, useImperativeHandle, useState} from "react";
import {ModalForm} from "declarando";
import {RowDescargarLibroContable} from "./RowDescargarLibroContable";
import {ContabilidadV2Repository} from "../../../../repositories/ContabilidadV2Repository";

export const ModalDescargarLibrosContables = forwardRef(({row = null, years, ...props}, ref) => {

    const [open, setOpen] = useState(false);
    const repository = new ContabilidadV2Repository();

    useImperativeHandle(ref, () => ({
        openModal() {
            setOpen(true)
        },
    }));

    const descargarFacturasRecibidas = async (year) => repository.descargarCsvGastosConFactura(row.id, year);
    const descargarFacturasEmitidas = async (year) => repository.descargarCsvIngresosConFactura(row.id, year);
    const descargarIngresos = async (year) => repository.descargarCsvIngresos(row.id, year);
    const descargarGastos = async (year) => repository.descargarCsvGastos(row.id, year);

    return <ModalForm title="Descargar libros contables"
                      isOpen={open}
                      handleCancel={() => setOpen(false)}
                      showAction={false}
                      busy={false}>
        <RowDescargarLibroContable label="Libro contable de facturas emitidas" years={years}
                                   handler={descargarFacturasEmitidas}/>
        <RowDescargarLibroContable label="Libro contable de facturas recibidas" years={years}
                                   handler={descargarFacturasRecibidas}/>
        <RowDescargarLibroContable label="Libro contable de ingresos" years={years} handler={descargarIngresos}/>
        <RowDescargarLibroContable label="Libro contable de gastos" years={years} handler={descargarGastos}/>
    </ModalForm>
})