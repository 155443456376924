import React from 'react';
import { ThemeProvider, theme } from 'declarando';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch } from 'react-router-dom';

import 'book/src/assets/css/bootstrap.min.scss';
import 'book/src/assets/sass/skin.scss';
import './assets/sass/overrides.scss';
import './assets/css/app.css';

import baseRoutes from './routes/baseRoutes';

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <HashRouter>
            <Switch>
                {baseRoutes.map((prop, key) => {
                    return (
                        <Route
                            path={prop.path}
                            render={(props) => <prop.component routes={prop.routes} {...props} />}
                            key={key}
                        />
                    );
                })}
            </Switch>
        </HashRouter>
    </ThemeProvider>,
    document.getElementById('root'),
);

