import React, {useRef, useState} from "react";
import {ButtonSolid, Grid, P, Toast} from "declarando";

export const RowDescargarLibroContable = ({label, years, handler}) => {
    const gridProps = {
        gridTemplateColumns: ["auto", "270px 1fr max-content"], gridGap: "xs"
    }
    const [selectedYear, setSelectedYear] = useState(null)
    const [loading, setLoading] = useState(false)
    const toastRef = useRef();

    const mensajeInicioDescarga = () => toastRef.current.notifySuccess('La descarga del archivo está en proceso', 'Se ha iniciado la descarga');
    const mensajeErrorDescarga = () => toastRef.current.notifyError('Se ha producido un error al descargar el archivo', 'No se ha podido realizar la descarga');


    const onClick = async () => {
        setLoading(true)
        mensajeInicioDescarga();
        await handler(selectedYear)
            .catch
            (() => {
                mensajeErrorDescarga();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return <Grid {...gridProps}>
        <P>{label}</P>
        <select value={selectedYear} onChange={(e) => setSelectedYear(e.currentTarget.value)}>
            <option value="">Seleccionar Año</option>
            {years.map(year => <option key={'year-'+year.label} value={year.value}>{year.label}</option>)}
        </select>
        <ButtonSolid loading={loading} icon={'arrow-circle-down'} variant="secondary" onClick={() => {
            if (!selectedYear) return;
            onClick()
        }}>Descargar CSV</ButtonSolid>
        <Toast ref={toastRef}/>
    </Grid>
}