import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class PendingPlansChangeActions extends Component {
    render() {
        return (
            <div className={'table-element-actions'}>
                <Button id={'edit_' + this.props.row.id} onClick={() => this.props.handleEdition(this.props.row)}>
                    <i className={'fa fa-pencil-square-o'}></i>
                </Button>
                &nbsp;
                <Button id={'delete_' + this.props.row.id} onClick={() => this.props.handleDelete(this.props.row.id)}>
                    <i className={'fa fa-trash-o'}></i>
                </Button>
            </div>
        );
    }
}

export default PendingPlansChangeActions;
