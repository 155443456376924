import { P } from 'declarando';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import './Toast.css';

const ToastWrapper = () => {
    return (
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            style={{ width: '420px' }}
            pauseOnHover={false}
            theme="light"
            closeButton={false}
            progressStyle={{ visibility: 'hidden' }}
        />
    );
};

const ToastMessage = ({ message, title }) => {
    return (
        <>
            <P color="content.Body1" fontWeight={700}>
                {title}
            </P>
            <P small color="content.Body1">
                {message}
            </P>
        </>
    );
};

export { toast, ToastWrapper, ToastMessage };
