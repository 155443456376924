class AdviceProduct {
    static CONSEJO = '0';
    static PRODUCTO = '1';

    data = [];

    constructor(data) {
        data = [
            { id: AdviceProduct.CONSEJO, name: 'Consejo' },
            { id: AdviceProduct.PRODUCTO, name: 'Producto' },
        ];

        this.data = data;
    }

    getOptions() {
        let options = [];
        for (let i = 0; i < this.data.length; i++) {
            options[i] = {
                value: this.data[i].id,
                label: this.data[i].name,
            };
        }
        return options;
    }

    getOptionOfId(key) {
        let indexFound = null;
        for (let index = 0; index < this.data.length; index++) {
            if (this.data[index].id === key) indexFound = index;
        }
        let result =
            indexFound != null
                ? {
                      value: this.data[indexFound].id,
                      label: this.data[indexFound].name,
                  }
                : null;
        return result;
    }

    getLabelOfId(key) {
        let indexFound = null;
        for (let index = 0; index < this.data.length; index++) {
            if (this.data[index].id === key) indexFound = index;
        }
        let result = indexFound !== null ? this.data[indexFound].name : null;

        return result;
    }
}

export default AdviceProduct;
