class Agents {
    data = [];

    constructor(data) {
        this.data = data;
    }

    getOptions() {
        let options = [];
        for (let i = 0; i < this.data.length; i++) {
            options[i] = {
                value: this.data[i].id,
                label: this.data[i].name,
            };
        }
        return options;
    }

    getOptionOfId(key) {
        let indexFound = null;
        for (let index = 0; index < this.data.length; index++) {
            if (this.data[index].id === key) indexFound = index;
        }
        let result =
            indexFound != null
                ? {
                      value: this.data[indexFound].id,
                      label: this.data[indexFound].name,
                  }
                : null;
        return result;
    }

    getLabelOfId(key) {
        let indexFound = null;
        for (let index = 0; index < this.data.length; index++) {
            if (this.data[index].id === key) indexFound = index;
        }
        return indexFound != null ? this.data[indexFound].name : null;
    }
}

export default Agents;
