import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import PlansChangeRepository from '../../repositories/PlansChangeRepository';
import Planes from '../../components/ListsOptions/Planes';
import Date from '../../assets/utils/Date';
import debounce from 'debounce-promise';
import PlanChangeModal from './PlanChangeModal';
import { ActionButton, Content, ContentContainer, ContentToolbelt, Input, Label, ServerSideTable } from 'book';
import PendingPlansChangeActions from './PendingPlansChangeActions';
import UserV2Repository from "../../repositories/UserV2Repository";

class PlansChange extends Component {
    constructor(props) {
        super(props);

        this.changesPlansRepository = new PlansChangeRepository();
        this.userRepository = new UserV2Repository();

        this.loadUsers = debounce(this.loadUsersImmediate, 500, {
            loading: true,
        });

        this.state = {
            filter: '',
            change: {
                paytype: null,
                userId: '',
                date: '',
                plan: '',
                months: 1,
                price: 0,
                discount: 0,
                reason: '',
                observations: '',
            },
            users: [],
            changeAddModalOpen: false,
            changeEditModalOpen: false,
            deleteChangeModalOpen: false,
            deletePlanChangeId: null,
            errorMessage: '',
            errorMonths: '',
            isPendingList: true,
            forceUpdate: false,
            tab: 'pendientes',
        };
    }

    loadUsersImmediate = (filter) => {
        if (filter) {
            return this.userRepository.getUsersList(filter).then((response) => {
                return response.data;
            });
        } else {
            return new Promise((resolve) => resolve([]));
        }
    };

    resetChange = () => {
        this.setState({
            change: {
                paytype: null,
                userId: '',
                date: '',
                plan: '',
                months: 1,
                price: 0,
                discount: 0,
                reason: '',
                observations: '',
            },
            isValidForm: false,
            errorMessage: '',
            errorMonths: '',
        });
    };

    handleAddition = () => {
        this.setState({
            changeAddModalOpen: true,
        });
    };

    handleSaveClick = () => {
        this.changesPlansRepository
            .savePlanChange(this.state.change)
            .then((response) => {
                this.setState({
                    change: { ...this.state.change, userId: response.data },
                });
                this.closeAddChangeModal();
                this.closeEditChangeModal();
                this.setForceUpdate(true);
            })
            .catch((error) => {
                this.setState({
                    errorMessage: 'Ya existe un cambio de plan de este usuario con esta fecha',
                });
            });
    };

    closeAddChangeModal = () => {
        this.setState(
            {
                changeAddModalOpen: false,
            },
            this.resetChange,
        );
    };

    handleEdition = (change) => {
        this.setState(
            {
                changeEditModalOpen: true,
                change: change,
            },
            this.checkValidForm,
        );
    };

    closeEditChangeModal = () => {
        this.setState(
            {
                changeEditModalOpen: false,
            },
            this.resetChange,
        );
    };

    handleDelete = (changeId) => {
        this.setState({
            deletePlanChangeId: changeId,
            deleteChangeModalOpen: true,
        });
    };

    deleteChange = () => {
        this.changesPlansRepository.deletePlanChange(this.state.deletePlanChangeId).then((response) => {
            if (response.success) {
                this.closeDeleteChangeModal();
                this.resetChange();
                this.setForceUpdate(true);
            } else {
                this.setState({
                    errorMessage: response.message,
                });
            }
        });
    };

    closeDeleteChangeModal = () => {
        this.setState(
            {
                deleteChangeModalOpen: false,
            },
            this.resetChange,
        );
    };

    checkValidForm() {
        this.setState({
            isValidForm: this.isValidForm(),
        });
    }

    isValidForm() {
        let planValidation = false;

        if (
            this.state.change.plan &&
            ((this.state.change.plan === Planes.FREE_KEY && this.state.change.reason) || this.isZeroMonthsAndFree())
        ) {
            planValidation = true;
        }

        return planValidation && this.state.change.userId && this.state.change.date && this.state.change.observations;
    }

    isZeroMonthsAndFree = () => {
        if (String(this.state.change.plan) !== Planes.FREE_KEY && String(this.state.change.months) === '0') {
            this.setState({
                errorMonths: 'La opción de 0 meses solo está disponible cuando el cambio de plan es Free',
            });
            return false;
        } else {
            this.setState({
                errorMonths: '',
            });
            return true;
        }
    };

    loadUserCurrentDetails = () => {
        this.changesPlansRepository.getUserDetails(this.state.change.userId).then((response) => {
            const d = response.data;
            this.setState(
                {
                    change: {
                        ...this.state.change,
                        type: d.type,
                        date: d.nextPaymentDate,
                        plan: d.plan_id || Planes.FREE_KEY,
                        months: d.months || 0,
                        price: d.price || 0,
                        discount: d.discount || 0,
                    },
                },
                this.isValidForm,
            );
        });
    };

    handleUserChange = (value) => {
        this.setState(
            {
                change: { ...this.state.change, userId: value },
            },
            this.loadUserCurrentDetails,
        );
    };

    handleDateChange = (value) => {
        if (value.isValid()) {
            let date = Date.formatDateToString(value);

            this.setState(
                {
                    change: { ...this.state.change, date: date },
                },
                this.checkValidForm,
            );
        }
    };

    handlePlanChange = (value) => {
        const isFree = value === Planes.FREE_KEY;
        this.setState(
            {
                change: {
                    ...this.state.change,
                    plan: value,
                    months: isFree ? 0 : this.state.change.months,
                    price: isFree ? 0 : this.state.change.price,
                    discount: isFree ? 0 : this.state.change.discount,
                },
            },
            this.checkValidForm,
        );
    };

    handleMonthsChange = (value) => {
        if (!isNaN(value)) {
            this.setState(
                {
                    change: { ...this.state.change, months: value },
                },
                this.checkValidForm,
            );
        }
    };

    handlePriceChange = (value) => {
        if (!isNaN(value)) {
            this.setState({
                change: { ...this.state.change, price: value },
            });
        }
    };

    handleDiscountChange = (value) => {
        if (!isNaN(value)) {
            this.setState({
                change: { ...this.state.change, discount: value },
            });
        }
    };

    handleEventChange = (value) => {
        this.setState(
            {
                change: { ...this.state.change, event: value },
            },
            this.checkValidForm,
        );
    };

    handleReasonChange = (value) => {
        this.setState(
            {
                change: { ...this.state.change, reason: value },
            },
            this.checkValidForm,
        );
    };

    handleObservationsChange = (value) => {
        this.setState(
            {
                change: { ...this.state.change, observations: value },
            },
            this.checkValidForm,
        );
    };

    handleFilterChange = (value) => {
        this.setState({
            filter: value,
        });
    };

    handleTableListChange = (isPendingList) => {
        this.setState({
            isPendingList: isPendingList,
            filter: '',
            forceUpdate: true,
        });
    };

    setForceUpdate = (bool) => {
        this.setState({
            forceUpdate: bool,
        });
    };

    render() {
        let activeFilterStyles = {
            cursor: 'pointer',
            fontWeight: '500',
            color: 'white',
            background: '#3CA3F9',
            padding: '5px 15px',
            display: 'inline-block',
            fontSize: '14px',
            lineHeight: '14px',
            borderRadius: '24px',
        };

        let inactiveFilterStyles = {
            cursor: 'pointer',
            color: '#333',
            fontWeight: '500',
            padding: '5px 15px',
        };
        return (
            <>
                <Content id="cambioPlanes" title={'Listado de cambios de plan'}>
                    <ContentToolbelt>
                        <Row>
                            <Col sm={12}>
                                <ActionButton
                                    title={'Añadir consejo'}
                                    small
                                    className={'pull-right font-weight-500'}
                                    onClick={this.handleAddition}
                                >
                                    Añadir cambio
                                    <i className={'fa fa-calendar ml-p5'} />
                                </ActionButton>
                            </Col>
                        </Row>
                    </ContentToolbelt>
                    <ContentContainer>
                        <Row>
                            <Col className={'mb-1'} xs={12} sm={3} md={4}>
                                <Label theme={'primary'}>Filtrar por contenido</Label>
                                <Input
                                    type="text"
                                    name="filterAdvices"
                                    className={'mb-0'}
                                    placeholder="Escribe algo..."
                                    value={this.state.filter}
                                    onChange={(e) => this.handleFilterChange(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className={'mt-4 mb-2'}>
                            <Col xs={12}>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        padding: '0',
                                        background: 'rgb(233, 234, 242)',
                                        borderRadius: '24px',
                                    }}
                                >
                                    <span
                                        style={this.state.isPendingList ? activeFilterStyles : inactiveFilterStyles}
                                        onClick={() => this.handleTableListChange(true)}
                                    >
                                        Pendientes
                                    </span>
                                    <span
                                        style={!this.state.isPendingList ? activeFilterStyles : inactiveFilterStyles}
                                        onClick={() => this.handleTableListChange(false)}
                                    >
                                        Histórico
                                    </span>
                                </span>
                            </Col>
                        </Row>
                        <Row className={'mb-2'}>
                            <Col xs={12}>
                                {this.state.isPendingList ? (
                                    <ServerSideTable
                                        id={'pendingPlanListTable'}
                                        handleGet={(page, rows, filters, orders) =>
                                            this.changesPlansRepository.getPlansChange(
                                                page,
                                                rows,
                                                filters,
                                                orders,
                                                this.state.isPendingList,
                                            )
                                        }
                                        updateStatus={this.state.forceUpdate}
                                        setForceUpdate={this.setForceUpdate}
                                        filters={{
                                            filter: this.state.filter,
                                        }}
                                        orders={[{ data: 'date', order: 'desc' }]}
                                        cols={[
                                            {
                                                name: 'Id',
                                                data: 'userId',
                                                md: 1,
                                            },
                                            {
                                                name: 'Email',
                                                data: 'email',
                                                md: 2,
                                            },
                                            {
                                                name: 'Fecha ejecución',
                                                data: 'date',
                                                md: 1,
                                            },
                                            {
                                                name: 'Plan',
                                                data: 'plan',
                                                md: 1,
                                            },
                                            {
                                                name: 'Precio €',
                                                data: 'price',
                                                md: 1,
                                            },
                                            {
                                                name: 'Descuento %',
                                                data: 'discount',
                                                md: 1,
                                            },
                                            {
                                                name: 'Meses',
                                                data: 'months',
                                                md: 1,
                                            },
                                            {
                                                name: 'Acciones',
                                                data: null,
                                                md: 2,
                                                handler: (col, row, data) => {
                                                    return (
                                                        <PendingPlansChangeActions
                                                            row={row}
                                                            handleEdition={this.handleEdition}
                                                            handleDelete={this.handleDelete}
                                                        />
                                                    );
                                                },
                                            },
                                        ]}
                                    />
                                ) : (
                                    <ServerSideTable
                                        id={'historicalPlanListTable'}
                                        handleGet={(page, rows, filters, orders) =>
                                            this.changesPlansRepository.getPlansChange(
                                                page,
                                                rows,
                                                filters,
                                                orders,
                                                this.state.isPendingList,
                                            )
                                        }
                                        updateStatus={this.state.forceUpdate}
                                        setForceUpdate={this.setForceUpdate}
                                        filters={{
                                            filter: this.state.filter,
                                        }}
                                        orders={[{ data: 'date', order: 'desc' }]}
                                        cols={[
                                            {
                                                name: 'Id',
                                                data: 'userId',
                                                md: 1,
                                            },
                                            {
                                                name: 'Email',
                                                data: 'email',
                                                md: 2,
                                            },
                                            {
                                                name: 'Fecha cambio',
                                                data: 'date',
                                                md: 1,
                                            },
                                            {
                                                name: 'Plan destino',
                                                data: 'plan',
                                                md: 1,
                                            },
                                            {
                                                name: 'Precio destino',
                                                data: 'price',
                                                md: 1,
                                            },
                                            {
                                                name: 'Descuento destino',
                                                data: 'discount',
                                                md: 1,
                                            },
                                            {
                                                name: 'Meses destino',
                                                data: 'months',
                                                md: 1,
                                            },
                                            {
                                                name: 'Razón',
                                                data: 'reason',
                                                md: 1,
                                            },
                                            {
                                                name: 'Observaciones',
                                                data: 'observations',
                                                md: 2,
                                            },
                                        ]}
                                    />
                                )}
                            </Col>
                        </Row>
                    </ContentContainer>
                </Content>
                <PlanChangeModal
                    modalId="add-change-modal"
                    title="Nuevo Cambio de Plan"
                    errorMessage={this.state.errorMessage}
                    modalOpen={this.state.changeAddModalOpen}
                    isValid={this.state.isValidForm}
                    loadUsers={this.loadUsers}
                    handleUserChange={this.handleUserChange}
                    handleDateChange={this.handleDateChange}
                    handlePlanChange={this.handlePlanChange}
                    handleMonthsChange={this.handleMonthsChange}
                    handlePriceChange={this.handlePriceChange}
                    handleDiscountChange={this.handleDiscountChange}
                    handleEventChange={this.handleEventChange}
                    handleReasonChange={this.handleReasonChange}
                    handleObservationsChange={this.handleObservationsChange}
                    toggleModal={this.closeAddChangeModal}
                    handleSubmit={this.handleSaveClick}
                    data={this.state.change}
                    users={this.state.users}
                    errorMonths={this.state.errorMonths}
                />

                <PlanChangeModal
                    modalId="edit-change-modal"
                    title="Modificar Cambio de Plan"
                    errorMessage={this.state.errorMessage}
                    modalOpen={this.state.changeEditModalOpen}
                    isValid={this.state.isValidForm}
                    handleUserChange={this.handleUserChange}
                    handleDateChange={this.handleDateChange}
                    handlePlanChange={this.handlePlanChange}
                    handleMonthsChange={this.handleMonthsChange}
                    handlePriceChange={this.handlePriceChange}
                    handleDiscountChange={this.handleDiscountChange}
                    handleEventChange={this.handleEventChange}
                    handleReasonChange={this.handleReasonChange}
                    handleObservationsChange={this.handleObservationsChange}
                    toggleModal={this.closeEditChangeModal}
                    handleSubmit={this.handleSaveClick}
                    data={this.state.change}
                    users={this.state.users}
                    loadUsers={this.loadUsers}
                    errorMonths={this.state.errorMonths}
                />

                <Modal id="delete-modal" show={this.state.deleteChangeModalOpen}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar borrado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>¿Estás seguro de eliminar este cambio?</Modal.Body>
                    <Modal.Footer>
                        <Button id="delete-button" className={'btn-danger'} onClick={this.deleteChange}>
                            Eliminar
                        </Button>
                        <Button onClick={this.closeDeleteChangeModal}>Cancelar</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default PlansChange;
