import AdminV2Repository from "./AdminV2Repository";

export default class PlansChangeRepository extends AdminV2Repository {
    getPlansChange(page, rows, filters, orders, isPending) {
        if (isPending) {
           return this.getPlansChangePending(page, rows, filters, orders);
        } else {
           return this.getPlansChangeHistorical(page, rows, filters, orders);
        }
    }

    getPlansChangePending(page, rows, filters, orders) {
        return this.post(
            '/cambio-planes/pendientes',
            JSON.stringify({
                page,
                rows,
                filters,
                orders,
            }),
        );
    }

    getPlansChangeHistorical(page, rows, filters, orders) {
        return this.post(
            '/cambio-planes/finalizados',
            JSON.stringify({
                page,
                rows,
                filters,
                orders,
            }),
        );
    }

    savePlanChange(data) {
        return this.post(
            '/cambio-plan',
            JSON.stringify({
                data,
            }),
        );
    }

    deletePlanChange(id) {
        return this.delete('/cambio-plan/' + id);
    }

    getUserDetails(userId) {
        return this.get('/cambio-plan/' + userId);
    }
}
