import React, { Component } from 'react';
import { Button, Table } from 'react-bootstrap';

class AdviserAdviceTable extends Component {
    render() {
        return (
            <Table striped bordered condensed hover responsive>
                <thead>
                    <tr>
                        <th>Activo</th>
                        <th className={'col-md-5'}>Texto consejo</th>
                        <th>Sección</th>
                        <th>Usuario</th>
                        <th>Visto</th>
                        <th>Clic</th>
                        <th>Puntuación</th>
                        <th>Fecha</th>
                        <th>Asesor</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.advices.map((advice, key) => {
                        return (
                            <tr key={key}>
                                <td>
                                    <input
                                        id={'active'}
                                        type="checkbox"
                                        onChange={() => this.props.toggleStatus(advice.id, advice.status)}
                                        checked={advice.status}
                                    />
                                </td>
                                <td>{advice.text}</td>
                                <td>{advice.section.name}</td>
                                <td>{advice.username}</td>
                                <td>{advice.viewed}</td>
                                <td>{advice.clicked}</td>
                                <td>
                                    {advice.score}/{advice.count}
                                </td>
                                <td>{advice.created}</td>
                                <td>{advice.agent.name}</td>
                                <td>
                                    <Button
                                        id={'edit_' + advice.id}
                                        onClick={() => this.props.handleEdition(advice.id)}
                                    >
                                        <i className={'fa fa-pencil-square-o'}></i>
                                    </Button>
                                    &nbsp;
                                    <Button
                                        id={'delete_' + advice.id}
                                        onClick={() => this.props.handleDelete(advice.id)}
                                    >
                                        <i className={'fa fa-trash-o'}></i>
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    }
}

export default AdviserAdviceTable;
