import V2Repository from '../../../repositories/V2Repository';

export const useSetFechaBajaAutonomo = () => {
    const userRepository = new V2Repository();

    const postFechaBajaAutonomo = (userId, fechaBajaAutonomo) => {
        return userRepository.setFechaBajaAutonomo(userId, fechaBajaAutonomo);
    };

    return { postFechaBajaAutonomo };
};
