import Cookies from 'js-cookie';

export default class JWT {
    static getToken = () => {
        return Cookies.get(process.env.REACT_APP_TOKEN_NAME);
    };

    static parseToken() {
        const token = JWT.getToken();
        return JSON.parse(atob(token.split('.')[1]));
    }

    static removeToken() {
        Cookies.remove(process.env.REACT_APP_TOKEN_NAME, { path: '/', domain: '.declarando.es' });
    }
}
