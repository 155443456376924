import AdminV2Repository from "./AdminV2Repository";

export default class InvitationsRepository extends AdminV2Repository {
    getPagedWithFilter(page, rows, filter) {
        return this.post(
            '/invitaciones',
            JSON.stringify({
                page,
                rows,
                filter,
            }),
        );
    }
}
