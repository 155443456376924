import React, { Component } from 'react';

class Header extends Component {
    constructor(props) {
        super(props);
        this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
        this.state = {
            sidebarExists: false,
        };
    }

    mobileSidebarToggle(e) {
        if (this.state.sidebarExists === false) {
            this.setState({
                sidebarExists: true,
            });
        }

        e.preventDefault();
        document.documentElement.classList.toggle('nav-open');
        let node = document.createElement('div');
        node.id = 'bodyClick';
        node.onclick = function () {
            this.parentElement.removeChild(this);
            document.documentElement.classList.toggle('nav-open');
        };
        document.body.appendChild(node);
    }

    render() {
        return (
            <div>
                <header className="App-header">
                    <button type="button" className="navbar-toggle" onClick={this.mobileSidebarToggle}>
                        <span className="sr-only">Navegación</span>
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>
                </header>
            </div>
        );
    }
}

export default Header;
