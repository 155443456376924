import Lost from '../views/Lost';
import App from '../components/App';
import appRoutes from './appRoutes';

const baseRoutes = [
    {
        name: 'Login',
        path: '/lost',
        icon: 'fa fa-home',
        component: Lost,
        id: 'lost',
    },
    {
        name: 'App',
        path: '/',
        icon: 'fa fa-home',
        component: App,
        routes: appRoutes,
        id: 'app',
    },
];

export default baseRoutes;
