import React, { Component } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import OrderRepository from '../repositories/OrderRepository';
import _ from 'lodash';
import Datetime from 'react-datetime';
import Date from '../assets/utils/Date';
import '../../src/assets/css/react-datetime.css';
import { ActionButton, Content, ContentToolbelt, ContentContainer, Label, Input, LinkButton, Spinner } from 'book';
import UtilsDate from '../assets/utils/Date';

class OrderRow extends Component {
    render() {
        const { data } = this.props;
        return (
            <tr>
                <td>{data.user_id}</td>
                <td>{data.email}</td>
                <td>{data.username}</td>
                <td>{data.nif}</td>
                <td>{data.plan}</td>
                <td>{Date.formatFullTimeStringtoDate(data.date).format(Date.visualFormat)}</td>
                <td>{data.amount} &euro;</td>
                <td>{data.type}</td>
                <td>{data.upgrade ? 'Sí' : 'No'}</td>
            </tr>
        );
    }
}

class OrdersTable extends Component {
    render() {
        return (
            <Table id={'orders-table'} striped bordered condensed hover responsive>
                <thead>
                    <tr>
                        <th>User Id</th>
                        <th>Email</th>
                        <th>Nombre</th>
                        <th title={'Documento Oficial Identidad'}>DOI</th>
                        <th>Plan</th>
                        <th>F.Upgrade</th>
                        <th>Importe</th>
                        <th>Tipo Pago</th>
                        <th>Upgrade</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.orders.map((order, key) => {
                        return <OrderRow data={order} key={key} {...this.props} />;
                    })}
                </tbody>
            </Table>
        );
    }
}

class Orders extends Component {
    constructor(props) {
        super(props);

        this.orderRepository = new OrderRepository();

        this.reloadOrders = _.debounce(this.loadOrders, 300);

        this.state = {
            page: 0,
            pageRows: 10,
            dateIni: null,
            dateEnd: null,
            filter: '',
            orders: [],
            resetOrderId: null,
            showFilters: false,
            loadingOrders: false,
        };
    }

    componentDidMount() {
        this.loadOrders();
    }

    handleToggleshowFilters = () => {
        this.setState(() => ({ showFilters: !this.state.showFilters }));
    };

    loadOrders() {
        this.setState(() => ({ loadingOrders: true }));
        this.orderRepository
            .getPagedWithFilter(
                this.state.page,
                this.state.pageRows,
                this.state.filter,
                this.state.dateIni,
                this.state.dateEnd,
            )
            .then((response) => {
                this.setState({
                    orders: response.data,
                });
            })
            .finally(() => this.setState(() => ({ loadingOrders: false })));
    }

    handleFilterChange = (value) => {
        this.setState(
            {
                filter: value,
            },
            function () {
                this.reloadOrders();
            },
        );
    };

    handleNextPage = () => {
        let page = this.state.page;
        let self = this;

        this.setState(
            {
                page: page + 1,
            },
            function () {
                self.loadOrders();
            },
        );
    };

    handlePreviousPage = () => {
        let page = this.state.page - 1;
        let self = this;
        if (page < 0) page = 0;
        this.setState(
            {
                page: page,
            },
            function () {
                self.loadOrders();
            },
        );
    };

    handleGetCSVClick = () => {
        window.open(
            process.env.REACT_APP_API_URL +
                '/v2/admin/fichero/contrataciones?' +
                'page=0' +
                '&rows=99999' +
                '&filter=' +
                this.state.filter +
                '&dateini=' +
                this.state.dateIni +
                '&dateend=' +
                this.state.dateEnd,
        );
    };

    handleDateIniChange = (date) => {
        let self = this;

        if (date && date.isValid()) {
            this.setState(
                {
                    dateIni: Date.formatDateToString(date),
                },
                function () {
                    self.loadOrders();
                },
            );
        }
    };

    handleDateEndChange = (date) => {
        let self = this;

        if (date && date.isValid()) {
            this.setState(
                {
                    dateEnd: Date.formatDateToString(date),
                },
                function () {
                    self.loadOrders();
                },
            );
        }
    };

    render() {
        return (
            <Content id="contrataciones" title={'Contrataciones'}>
                <ContentToolbelt>
                    <Row>
                        <Col sm={12} className={'d-flex justify-content-end'}>
                            <ActionButton
                                title={'Action'}
                                small
                                className={'font-weight-500'}
                                onClick={() => {
                                    window.location.href = '#/recurrent-visa';
                                }}
                            >
                                Recurrentes
                                <i className={'fa fa-cc-visa ml-p5'} />
                            </ActionButton>
                            <ActionButton
                                title={'Action'}
                                small
                                className={'font-weight-500 ml-1'}
                                onClick={this.handleGetCSVClick}
                            >
                                CSV
                                <i className={'fa fa-table ml-p5'} />
                            </ActionButton>
                        </Col>
                    </Row>
                </ContentToolbelt>
                <ContentContainer>
                    <Row>
                        <Col className={'mb-1'} xs={12} sm={3} md={4}>
                            <Label theme={'primary'}>Identificador</Label>
                            <Input
                                type="text"
                                name="filter"
                                className={'mb-0'}
                                placeholder="ID, Nombre, Usuario, Email, DNI"
                                value={this.state.filter}
                                onChange={(e) => this.handleFilterChange(e.target.value)}
                            />
                        </Col>
                        <Col xs={12}>
                            <LinkButton
                                small
                                className={'text-primary font-weight-500 text-underline'}
                                onClick={this.handleToggleshowFilters}
                            >
                                {this.state.showFilters ? `Mostrar menos filtros` : 'Mostrar más filtros'}
                            </LinkButton>
                        </Col>
                    </Row>
                    {this.state.showFilters && (
                        <div className={'mt-2 mb-2'}>
                            <Row>
                                <Col className={'mb-1'} xs={12}>
                                    <Label theme={'primary'}>Fecha Contratación</Label>
                                </Col>
                                <Col className={'mb-1'} sm={3}>
                                    <Datetime
                                        className={''}
                                        dateFormat={UtilsDate.visualFormat}
                                        timeFormat={false}
                                        inputProps={{ placeholder: 'Fecha inicial' }}
                                        value={this.state.dateIni}
                                        onChange={(e) => this.handleDateIniChange(e)}
                                    />
                                </Col>
                                <Col className={'mb-1'} sm={3}>
                                    <Datetime
                                        className={''}
                                        dateFormat={UtilsDate.visualFormat}
                                        timeFormat={false}
                                        inputProps={{ placeholder: 'Fecha final' }}
                                        value={this.state.dateEnd}
                                        onChange={(e) => this.handleDateEndChange(e)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                    {this.state.loadingOrders ? (
                        <div
                            className="text-center"
                            style={{
                                border: '1px solid #E3E3E3',
                            }}
                        >
                            <Spinner onlyloader={false} />
                        </div>
                    ) : (
                        <Row className={'mt-4 mb-2'}>
                            <Col xs={12}>
                                {this.state.orders.length ? (
                                    <OrdersTable orders={this.state.orders} handleGetCSV={this.handleGetCSVClick} />
                                ) : (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: '1px solid #E3E3E3',
                                            height: '300px',
                                        }}
                                    >
                                        <span className="text-left d-inline-block">
                                            <p className="h4 font-weight-600 m-0">No se han encontrado resultados</p>
                                        </span>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col sm={12}>
                            <LinkButton
                                id={'page-previous'}
                                className={'pull-left font-weight-500'}
                                onClick={this.handlePreviousPage}
                            >
                                ⬅ Anterior
                            </LinkButton>
                            <LinkButton
                                id={'page-next'}
                                className={'pull-right font-weight-500'}
                                onClick={this.handleNextPage}
                            >
                                Siguiente ⮕
                            </LinkButton>
                        </Col>
                    </Row>
                </ContentContainer>
            </Content>
        );
    }
}

export default Orders;
