import React from "react";
import { Icon, ModalForm , P, Section, AlertMessage} from 'declarando';
import UserV2Repository from "../../../../repositories/UserV2Repository";

export const ModalDescargarInformeRenta = ({show, closeModal, info, userId}) => {
    return <ModalForm title="Descargar informe de renta"
                      isOpen={show}
                      handleCancel={closeModal}
                      handleAction={() => {
                        new UserV2Repository().descargarInformeRenta(userId);
                        closeModal();
                    }}
                      disabledAction={!info}
                      labelAction={<><Icon name="file-download-alt" /> {" "}Descargar informe de renta</>}
                      busy={info === null}
                      >
        <Section>
            <P>Para poder descargar el informe de renta, el usuario debe cumplir las reglas de presentación del modelo 100.</P>
            {info !== null ? <AlertMessage full tone={info ? 'success': 'error'} title={
                    info ? "El usuario cumple las reglas de presentación que son:"
                    : "El usuario NO cumple las reglas de presentación que son:"} message={
                    <>
                        <span>· Cumplir las reglas fiscales de activación </span><br/>
                        <span>· No tener el modelo 100 presentado </span><br/>
                        <span>· Haber presentado el modelo 390, en caso de tener la obligación </span>
                    </>}/>: null}
            
        </Section>

    </ModalForm>
}