import Repository from './Repository';
import JWT from '../utils/JWT';

class V2Repository extends Repository {
    constructor(notifyError) {
        super(notifyError);
        this.apiPath = this.apiPath + '/v2';
    }

    isAuthenticated() {
        const token = JWT.getToken();
        if (!token) {
            return Promise.reject('missing token');
        }
        return this.validateToken(token).then((response) => {
            if (response.valid) {
                return response;
            } else {
                return Promise.reject('auth error');
            }
        });
    }

    validateToken(token) {
        return this.post(
            '/validate/admin-token',
            JSON.stringify({
                token,
            }),
        );
    }

    impersonate(id, email) {
        return this.get('/admin/access/' + id + '/' + email);
    }

    getProvinces(all) {
        const sendValue = all ? 1 : 0;
        return this.get('/provincias/' + sendValue);
    }

    isNoEUCountry(countryId) {
        return this.get('/countries/check/isNOEU/' + countryId);
    }
    setFechaBajaAutonomo(id, fechaBaja) {
        return this.post(`/admin/user/${id}/fechaBajaAutonomo`, JSON.stringify({ fechaBajaAutonomo: fechaBaja }));
    }

    sendContability(id, data) {
        const request = new Request(`${process.env.REACT_APP_API_URL}/v2/admin/users/${id}/subida-contabilidad`, {
            method: 'POST',
            credentials: 'include',
            body: data,
        });
        return fetch(request);
        // return this.post(`/admin/user/${id}/subida-contabilidad `, data);
    }
}

export default V2Repository;
