import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import AuthenticatedRoute from './Auth/AuthenticatedRoute';
import UserZone from './UserZone';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
    render() {
        return (
            <div className="App">
                <Header />
                <UserZone />
                <Sidebar {...this.props} />
                <div className="main-panel is-admin-panel">
                    <Switch>
                        {this.props.routes.map((prop, key) => {
                            if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />;
                            return <AuthenticatedRoute path={prop.path} component={prop.component} key={key} />;
                        })}
                    </Switch>
                </div>
            </div>
        );
    }
}

export default App;
