import AdminV2Repository from "./AdminV2Repository";

export default class AdvicesRepository extends AdminV2Repository {
    getAdvices(page, rows, filter, status) {
        return this.post(
            '/consejos',
            JSON.stringify({
                page,
                rows,
                filter,
                status,
            }),
        );
    }

    saveAdvice(data) {
        return this.post(
            '/consejo',
            JSON.stringify({
                data,
            }),
        );
    }

    toggleAdviceStatus(id, prevStatus) {
        const newStatus = !prevStatus;
        return this.post(
            '/consejo/' + id + '/estado',
            JSON.stringify({
                status: newStatus,
            }),
        );
    }

    getAdvice(id) {
        return this.get('/consejo/' + id);
    }

    deleteAdvice(id) {
        return this.delete('/consejo/' + id);
    }

}
