import AdminV2Repository from "./AdminV2Repository";

export default class OrderRepository extends AdminV2Repository {
    getPagedWithFilter(page, rows, filter, dateini, dateend) {
        return this.post(
            '/contrataciones',
            JSON.stringify({
                page,
                rows,
                filter,
                dateini,
                dateend,
            }),
        );
    }
}
