import React, { Component } from 'react';
import { Row, Col, Table } from 'react-bootstrap';

import InvitationsRepository from '../repositories/InvitationsRepository';
import _ from 'lodash';
import { Content, ContentContainer, Input, Label, LinkButton, Spinner } from 'book';

class InvitationRow extends Component {
    render() {
        let { data } = this.props;
        let selected = '';

        return (
            <tr className={selected}>
                <td>{data.name}</td>
                <td>{data.mail}</td>
                <td>{data.phone}</td>
                <td>{data.invitator_mail}</td>
                <td>{data.is_user ? 'Sí' : 'No'}</td>
                <td>{data.is_client ? 'Sí' : 'No'}</td>
            </tr>
        );
    }
}

class InvitationsTable extends Component {
    render() {
        return (
            <Table id={'invitations-table'} striped bordered condensed hover responsive>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Teléfono</th>
                        <th>Usuario Invitador</th>
                        <th>Usuario</th>
                        <th>Cliente</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.invitations.map((invitation, key) => {
                        return <InvitationRow data={invitation} key={key} {...this.props} />;
                    })}
                </tbody>
            </Table>
        );
    }
}

class Invitations extends Component {
    constructor(props) {
        super(props);

        this.invitationsRepository = new InvitationsRepository();

        this.reloadInvitations = _.debounce(this.loadInvitations, 300);

        this.state = {
            page: 0,
            pageRows: 10,
            filter: '',
            invitations: [],
            loadingInvitations: false,
        };
    }

    componentDidMount() {
        this.loadInvitations();
    }

    loadInvitations() {
        this.setState(() => ({ loadingInvitations: true }));
        this.invitationsRepository
            .getPagedWithFilter(this.state.page, this.state.pageRows, this.state.filter)
            .then((response) => {
                this.setState({
                    invitations: response.data,
                });
            })
            .then(() => this.setState(() => ({ loadingInvitations: false })));
    }

    handleFilterChange(e) {
        this.setState(
            {
                filter: e.target.value,
            },
            function () {
                this.reloadInvitations();
            },
        );
    }

    handleNextPage() {
        let page = this.state.page;
        this.setState(
            {
                page: page + 1,
            },
            function () {
                this.loadInvitations();
            },
        );
    }

    handlePreviousPage() {
        let page = this.state.page - 1;
        if (page < 0) page = 0;
        this.setState(
            {
                page: page,
            },
            function () {
                this.loadInvitations();
            },
        );
    }

    render() {
        return (
            <Content id="invitaciones" title={'Gestión de Invitaciones'}>
                <ContentContainer>
                    <Row>
                        <Col className={'mb-1'} xs={12} sm={3} md={4}>
                            <Label theme={'primary'}>Identificador</Label>
                            <Input
                                type="text"
                                name="filterAdvices"
                                className={'mb-0'}
                                placeholder="ID, Nombre, Usuario, Email, DNI"
                                value={this.state.filter}
                                onChange={this.handleFilterChange.bind(this)}
                            />
                        </Col>
                    </Row>
                    <Row className={'mt-4 mb-2'}>
                        <Col xs={12}>
                            {this.state.loadingInvitations ? (
                                <div
                                    style={{
                                        border: '1px solid gainsboro',
                                    }}
                                >
                                    <Spinner onlyloader={false} />
                                </div>
                            ) : this.state.invitations.length ? (
                                <InvitationsTable invitations={this.state.invitations} />
                            ) : (
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{
                                        border: '1px solid #E3E3E3',
                                        height: '300px',
                                    }}
                                >
                                    <span className="text-left d-inline-block">
                                        <p className="h4 font-weight-600 m-0">No se han encontrado resultados</p>
                                    </span>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <LinkButton
                                id={'page-previous'}
                                className={'pull-left font-weight-500'}
                                onClick={this.handlePreviousPage.bind(this)}
                            >
                                ⬅ Anterior
                            </LinkButton>
                            <LinkButton
                                id={'page-next'}
                                className={'pull-right font-weight-500'}
                                onClick={this.handleNextPage.bind(this)}
                            >
                                Siguiente ⮕
                            </LinkButton>
                        </Col>
                    </Row>
                </ContentContainer>
            </Content>
        );
    }
}

export default Invitations;
