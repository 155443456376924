import Repository from './Repository';

class TarjetaDeclarandoRepository extends Repository {
    constructor(notifyError) {
        super(notifyError);
        this.apiPath = this.apiPath + '/v2/admin';
    }

    desbloquearTarjeta(id) {
        return this.post('/user/' + id + '/desbloquear-tarjeta');
    }
}

export default TarjetaDeclarandoRepository;
