import LocationsRepository from './LocationsRepository';
import JWT from '../utils/JWT';

export default class Repository {
    constructor() {
        this.locations = new LocationsRepository();
        this.apiPath = process.env.REACT_APP_API_URL;
    }

    get(url) {
        return this.call('GET', url);
    }

    post(url, data) {
        return this.call('POST', url, data);
    }

    put(url, data) {
        return this.call('PUT', url, data);
    }

    delete(url, data) {
        return this.call('DELETE', url, data);
    }

    call(method, url, data) {
        const endpoint = this.apiPath + url;

        let options = {
            method: method,
            credentials: 'include',
        };

        if (typeof data !== 'undefined') {
            options.body = data;
        }

        const request = new Request(endpoint, options);

        return fetch(request)
            .then((response) => {
                if (response.status === 401) {
                    JWT.removeToken();
                }
                return response.json();
            })
            .then((response) => {
                if (response.success || !response.message) {
                    return response;
                } else {
                    return Promise.reject(response.message);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async downloadFile(url) {
        let options = { method: 'GET', credentials: 'include' };
        const request = new Request(url, options);
        const response = await fetch(request);
        let json = null;
        try {
            json = await response.json();
        } catch (e) {
            window.location.href = url;
            return true;
        }

        const error = new Error('No se ha podido descargar el archivo');
        error.body = json;
        throw error;
    }
}
