class Autonomo {
    static ALL = 'Todos';
    static SI = 'Autonomo';
    static NO = 'No Autonomo';

    static ALL_KEY = 'ALL';
    static SI_KEY = '1';
    static NO_KEY = '0';

    static data = {
        [Autonomo.ALL_KEY]: Autonomo.ALL,
        [Autonomo.SI_KEY]: Autonomo.SI,
        [Autonomo.NO_KEY]: Autonomo.NO,
    };

    static getFullOptions = () => {
        return [Autonomo.getOptionAll()].concat(Autonomo.getOptions());
    };

    static getOptions = () => {
        return [Autonomo.getOptionOfKey(Autonomo.SI_KEY), Autonomo.getOptionOfKey(Autonomo.NO_KEY)];
    };

    static getOptionAll = () => {
        return Autonomo.getOptionOfKey(Autonomo.ALL_KEY);
    };

    static getOptionFromProfileData = (autonomo) => {
        if (autonomo) {
            return Autonomo.getOptionOfKey(Autonomo.SI_KEY);
        } else {
            return Autonomo.getOptionOfKey(Autonomo.NO_KEY);
        }
    };

    static getOptionOfKey = (value) => {
        return {
            value: value,
            label: Autonomo.getLabelOf(value),
        };
    };

    static getLabelOf = (key) => {
        return Autonomo.data[key];
    };
}

export default Autonomo;
