import AdminV2Repository from "./AdminV2Repository";

export default class FilesRepository extends AdminV2Repository {
    postProductLogo(id, formData) {
        return this.post('/fichero/logo-producto/' + id, formData);
    }

    getProductLogoUrl(id) {
        return process.env.REACT_APP_API_URL + '/v2/admin/fichero/logo-producto/' + id;
    }

    deleteProductLogo(id) {
        return this.delete('/fichero/logo-producto/' + id);
    }

}
