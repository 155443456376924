class ProductTypes {
    static SEGURO = '1';
    static PLAN_PENSIONES = '2';
    static HIPOTECA = '3';
    static PRESTAMO = '4';
    static OTROS = '5';

    data = [];

    constructor(data) {
        data = [
            { id: ProductTypes.SEGURO, name: 'Seguro' },
            { id: ProductTypes.PLAN_PENSIONES, name: 'Plan de pensiones' },
            { id: ProductTypes.HIPOTECA, name: 'Hipoteca' },
            { id: ProductTypes.PRESTAMO, name: 'Préstamo' },
            { id: ProductTypes.OTROS, name: 'Otros' },
        ];

        this.data = data;
    }

    getOptions() {
        let options = [];
        for (let i = 0; i < this.data.length; i++) {
            options[i] = {
                value: this.data[i].id,
                label: this.data[i].name,
            };
        }
        return options;
    }

    getOptionOfId(key) {
        let indexFound = null;
        for (let index = 0; index < this.data.length; index++) {
            if (this.data[index].id === key) indexFound = index;
        }
        let result =
            indexFound != null
                ? {
                      value: this.data[indexFound].id,
                      label: this.data[indexFound].name,
                  }
                : null;
        return result;
    }

    getLabelOfId(key) {
        let indexFound = null;
        for (let index = 0; index < this.data.length; index++) {
            if (this.data[index].id === key) indexFound = index;
        }
        let result = indexFound !== null ? this.data[indexFound].name : null;

        return result;
    }
}

export default ProductTypes;
