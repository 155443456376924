import AdminV2Repository from "./AdminV2Repository";

export class AdvicesV2Repository extends AdminV2Repository {
    getAgents() {
        return this.get('/consejos/agentes');
    }

    getSections() {
        return this.get('/consejos/secciones');
    }
}
