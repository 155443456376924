import React from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import ErrorLabel from './ErrorLabel';
import Datetime from 'react-datetime';
import '../../assets/css/react-datetime.css';
import Date from '../../assets/utils/Date';

class RecurrentVisaFormPart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorDate: '',
            errorCantidad: '',
            errorDiscount: '',
            errorCuotas: '',
        };
    }

    isValidated() {
        const validation = this._validateData();

        const errors = this._validationErrors(validation);

        this.setState({
            errorDate: errors.dateValMsg,
            errorCantidad: errors.cantidadValMsg,
            errorDiscount: errors.discountValMsg,
            errorCuotas: errors.cuotasValMsg,
        });

        return validation.dateVal && validation.cantidadVal && validation.discountVal && validation.cuotasVal;
    }

    _validateData() {
        return {
            dateVal: this.props.date && this.props.date.isValid(),
            cantidadVal: !isNaN(this.props.cantidad) && this.props.cantidad > 0,
            discountVal:
                !isNaN(this.props.discountPercent) &&
                this.props.discountPercent >= 0 &&
                this.props.discountPercent <= 100,
            cuotasVal: !isNaN(this.props.cuotas) && this.props.cuotas > 0,
        };
    }

    _validationErrors(obj) {
        return {
            dateValMsg: obj.dateVal ? '' : <ErrorLabel text="Debes introducir una fecha válida" />,
            cantidadValMsg: obj.cantidadVal ? '' : <ErrorLabel text="Debes introducir una cantidad válida" />,
            discountValMsg: obj.discountVal ? (
                ''
            ) : (
                <ErrorLabel text="Debes introducir un procentaje de descuento válido" />
            ),
            cuotasValMsg: obj.cuotasVal ? '' : <ErrorLabel text="Debes introducir un número váido" />,
        };
    }

    render() {
        return (
            <div>
                <Row>
                    <Col md={8} mdOffset={1}>
                        <FormGroup>
                            <ControlLabel>
                                Próximo Cobro <span className="text-danger">*</span>
                            </ControlLabel>
                            <Datetime
                                dateFormat={Date.visualFormat}
                                timeFormat={false}
                                value={this.props.date}
                                onChange={(e) => this.props.updateDate(e)}
                            />
                            {this.state.errorDate}
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <ControlLabel>&nbsp;</ControlLabel>
                            <div>
                                <input
                                    id={'q'}
                                    type="checkbox"
                                    onChange={(e) => this.props.updateActive(e.target.checked)}
                                    checked={this.props.active}
                                />
                                <label htmlFor={'q'}>&nbsp;Activo</label>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={5} mdOffset={1}>
                        <FormGroup>
                            <ControlLabel>
                                Cantidad <span className="text-danger">*</span>
                            </ControlLabel>
                            <FormControl
                                type="text"
                                name="cantidad"
                                value={this.props.cantidad}
                                onChange={(e) => this.props.updateCantidad(e.target.value)}
                            />
                            {this.state.errorCantidad}
                        </FormGroup>
                    </Col>
                    <Col md={5}>
                        <FormGroup>
                            <ControlLabel>
                                Cuotas <span className="text-danger">*</span>
                            </ControlLabel>
                            <FormControl
                                type="text"
                                name="cuotas"
                                value={this.props.cuotas}
                                onChange={(e) => this.props.updateCuotas(e.target.value)}
                            />
                            {this.state.errorCuotas}
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                Porcentaje de Descuento <span className="text-danger">*</span>
                            </ControlLabel>
                            <FormControl
                                type="text"
                                name="discountPercent"
                                value={this.props.discountPercent}
                                onChange={(e) => this.props.updateDiscount(e.target.value)}
                            />
                            {this.state.errorDiscount}
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default RecurrentVisaFormPart;
