import React, {forwardRef, useImperativeHandle} from 'react';
import {Box, ButtonSolid, Grid, ModalForm, P, Section, Select, Tag, Toast} from "declarando";
import {useModalReglas} from "./useModalReglas";
import * as PropTypes from "prop-types";

function TrimestreRow(props) {
    return <Grid

        alignItems="center"
        gridTemplateColumns="1fr max-content"
        style={{borderBottom: "1px solid #e8e8e8", paddingBottom: 8}}
    >
        <Grid
            alignItems="center"
            gridGap={"sm"}
            gridTemplateColumns={"max-content max-content"}
        >
            <P as={"span"} inline bold>
                {props.label}
            </P>
            {!props.disabled && <Tag variant={props.desactivadas ? "success" : "notification"}>
                {props.desactivadas ? "Reglas desactivadas": "Reglas activadas"}
            </Tag>}
        </Grid>
        <ButtonSolid
            variant={props.desactivadas ? "primary" : "secondary"}
            icon={props.desactivadas ? "padlock" : "unlock-alt"}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {props.desactivadas ? "Activar" : "Desactivar"}
        </ButtonSolid>
    </Grid>;
}

TrimestreRow.propTypes = {
    s: PropTypes.string,
    desactivadas: PropTypes.any,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};
export const ModalReglasReglasBloqueoContabilidad = forwardRef((props, ref) => {

    const {
        show,
        data,
        busy,
        yearsArray,
        openForm,
        closeForm,
        updateTrimestre,
        setYear,
        year,
        toastRef
    } = useModalReglas();

    useImperativeHandle(ref, () => ({
        openForm
    }));

    const parseLabel = (key) => `${key.replace(/([T])/g, 'º')} Trimestre`;

    const disableds = [];
    if(data) {
        const numRow = Object.keys(data).length;
    for (let i = 0; i < 4 - numRow; i++) {
        const trimestre = 4 - (4 - numRow) + (i + 1)
        const label = trimestre + "º Trimestre"
        disableds.push(<TrimestreRow key={"T" + trimestre} label={label} desactivadas={false} disabled
                                       onClick={() => null}/>);
    }
    }

    return (

            <ModalForm
                isOpen={show}
                busy={false}
                title={'Reglas de bloqueo de la contabilidad'}
                successChildren={null}
                success={false}
                showAction={false}
                handleCancel={closeForm}
            >
                <Box maxWidth={'200px'}>
                    <Select
                        label={"Año"}
                        value={String(year)} options={yearsArray} onChange={setYear} />
                </Box>
                {data ? (
                    <Section xs>
                        {Object.keys(data).map((key) => {
                            const { desactivadas } = data[key];
                            return (
                                <TrimestreRow key={key} label={parseLabel(key)} desactivadas={desactivadas} disabled={busy}
                                              onClick={() => updateTrimestre(key, !desactivadas)}/>
                            );
                        })}
                        {disableds}
                    </Section>
                ) : (
                    <P style={{ textAlign: 'center' }}>Cargando...</P>
                )}
                <Toast ref={toastRef}/>
            </ModalForm>
    );
});
