import React from 'react';
import moment from 'moment/moment';

class Date extends React.Component {
    static visualFormat = 'DD-MM-YYYY';
    static storeFormat = 'YYYY-MM-DD';
    static fullTimeFormat = 'YYYY-MM-DD HH:mm:ss';

    static formatDateToString(date) {
        return date.format(this.visualFormat);
    }

    static formatStringToDate(string) {
        return this.formatStringFormatToDate(string, this.visualFormat);
    }

    static formatFullTimeStringtoDate(string) {
        return this.formatStringFormatToDate(string, this.fullTimeFormat);
    }

    static formatStringFormatToDate(string, format) {
        return moment(string, format);
    }

    static formatNowToString() {
        return this.formatDateToString(this.getNow());
    }

    static getNow() {
        return moment();
    }
}

export default Date;
