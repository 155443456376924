import V2Repository from "./V2Repository";

class AdminRepository extends V2Repository {
    constructor(notifyError) {
        super(notifyError);
        this.apiPath = this.apiPath + '/admin';
    }
}

export default AdminRepository;
