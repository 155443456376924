import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class ModalConfirmacion extends Component {
    render() {
        return (
            <Modal id={this.props.id} show={this.props.open}>
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.text}</Modal.Body>
                <Modal.Footer>
                    <Button className={'btn-danger action-btn'} onClick={this.props.handleAction}>
                        {this.props.action}
                    </Button>
                    <Button onClick={this.props.handleCancel}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalConfirmacion;
