import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

class ModalDownload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            year: 0,
            period: 0,
            model: 0,
        };
    }

    getYears = () => {
        let years = [];
        let initialYear = new Date().getFullYear();
        for (let yearCounter = 2016; yearCounter <= initialYear; yearCounter++) {
            years.push(yearCounter);
        }
        return years;
    };

    handleChangeYear = (year) => {
        this.setState({
            year: year,
        });
    };

    handleChangePeriod = (period) => {
        this.setState({
            period: period,
        });
    };

    handleChangeModel = (model) => {
        this.setState({
            model: model,
        });
    };

    handleAction = () => {
        let destination = process.env.REACT_APP_API_URL;
        destination +=
            '/v2/admin/fichero/' +
            this.props.userId +
            '/modelo/' +
            this.state.model +
            '/' +
            this.state.year +
            '/' +
            this.state.period;
        window.open(destination);
    };

    render() {
        return (
            <Modal id={this.props.id} show={this.props.open}>
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <select value={this.state.year} onChange={(e) => this.handleChangeYear(e.target.value)}>
                                <option value={0} disabled>
                                    Año
                                </option>
                                {this.getYears().map((year) => {
                                    return (
                                        <option value={year} key={year}>
                                            {year}
                                        </option>
                                    );
                                })}
                            </select>
                        </Col>
                        <Col md={4}>
                            <select value={this.state.period} onChange={(e) => this.handleChangePeriod(e.target.value)}>
                                <option value={0} disabled>
                                    Periodo
                                </option>
                                {['1T', '2T', '3T', '4T'].map((period) => {
                                    return (
                                        <option value={period} key={period}>
                                            {period}
                                        </option>
                                    );
                                })}
                            </select>
                        </Col>
                        <Col md={4}>
                            <select value={this.state.model} onChange={(e) => this.handleChangeModel(e.target.value)}>
                                <option value={0} disabled>
                                    Modelo
                                </option>
                                {['111', '115', '130', '303'].map((model) => {
                                    return (
                                        <option value={model} key={model}>
                                            {model}
                                        </option>
                                    );
                                })}
                            </select>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={this.state.year === 0 || this.state.period === 0 || this.state.model === 0}
                        className={'btn-danger action-btn'}
                        onClick={this.handleAction}
                    >
                        {this.props.action}
                    </Button>
                    <Button onClick={this.props.handleCancel}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalDownload;
