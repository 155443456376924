import React, { Component } from 'react';
import { ModalNotification } from 'book';
import UserV2Repository from "../../repositories/UserV2Repository";

class ModalResumeModelsCockpit extends Component {
    constructor(props) {
        super(props);
        this.repository = new UserV2Repository();
        this.state = {
            resume: [],
            loading: true,
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const userId = this.props.userId;

        if (userId) {
            this.repository
                .getModelsResume(userId)
                .then((response) => {
                    this.setState({
                        loading: false,
                        resume: response.data,
                    });
                })
                .catch((error) => {
                    console.log('Error loading: ' + error);
                });
        }
    };

    render() {
        return (
            <ModalNotification
                id={'modale-detalle-presentados'}
                title={'Detalle de Modelos presentados'}
                isOpen={true}
                actionText="Entendido"
                handleAction={this.props.handleClose}
            >
                <table>
                    {this.state.loading ? (
                        <p>Loading...</p>
                    ) : (
                        this.state.resume.map((row) => {
                            return (
                                <tr>
                                    <td>{row.model}</td>
                                    <td>
                                        {row.done ? (
                                            <i className={'fa fa-2x fa-check'} style={{ color: '#00ff00' }} />
                                        ) : (
                                            <i className={'fa fa-2x fa-times'} style={{ color: '#ff0000' }} />
                                        )}
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </table>
            </ModalNotification>
        );
    }
}

export default ModalResumeModelsCockpit;
