import React, { Component } from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
    padding: 0 25px;
    width: 100%;
    background: transparent;
    height: 60px;
    transition: all 0.1s linear;
`;

class UserZone extends Component {
    // TODO: empower this section
    render() {
        return <Wrapper></Wrapper>;
    }
}

export default UserZone;
