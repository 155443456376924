class Contabilidad {
    static SI = 'Sí';
    static NO = 'No';

    static SI_KEY = '1';
    static NO_KEY = '0';

    static data = {
        [Contabilidad.SI_KEY]: Contabilidad.SI,
        [Contabilidad.NO_KEY]: Contabilidad.NO,
    };

    static getOptions = () => {
        return [Contabilidad.getOptionOfKey(Contabilidad.SI_KEY), Contabilidad.getOptionOfKey(Contabilidad.NO_KEY)];
    };

    static getOptionFromProfileData = (autonomo) => {
        if (autonomo) {
            return Contabilidad.getOptionOfKey(Contabilidad.SI_KEY);
        } else {
            return Contabilidad.getOptionOfKey(Contabilidad.NO_KEY);
        }
    };

    static getOptionOfKey = (value) => {
        return {
            value: value,
            label: Contabilidad.getLabelOf(value),
        };
    };

    static getLabelOf = (key) => {
        return Contabilidad.data[key];
    };
}

export default Contabilidad;
