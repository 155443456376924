//import { featureFlags } from '../config/featureFlag';
import V2Repository from './V2Repository';

export default class UserV2Repository extends V2Repository {
  getAllIaes() {
    return this.get('/iaes/lista');
  }

  getAgentChildren(id) {
    return this.get('/admin/agente/' + id + '/hijos/search')
  }

  getPagedWithFilter(page, rows, filters) {
    return this.post(
        '/admin/users',
        JSON.stringify({
          page,
          rows,
          filters,
        }),
    );
  }

    getUsersList(filter) {
        return this.post(
            '/admin/users/lista',
            JSON.stringify({
                filter,
            }),
        );
    }

    async getInfoInformeRenta(id) {
        /*if(featureFlags.developer('adrianM')) {
            return {tieneQuePresentar: true};
        }*/
        return this.get('/admin/user/' + id + '/modelo100/info');
    }

    async descargarInformeRenta(id) {
        const url = this.apiPath + '/admin/user/'+ id +'/modelo100/descargar';
        return this.downloadFile(url)
    }



    updateContab(id, estado) {
        return this.post('/admin/user/' + id + '/contabilidad/' + estado);
    }

    updateAutonomo(id, estado) {
        return this.post('/admin/user/' + id + '/autonomo/' + estado);
    }

    updateRenta(id, estado) {
        return this.post('/admin/user/' + id + '/renta/' + estado);
    }

    updateConciliacion(id, estado) {
        return this.post('/admin/user/' + id + '/conciliacion/' + estado);
    }

    updateTarjetas(id, estado) {
        return this.post('/admin/user/' + id + '/tarjetas/' + estado);
    }

    updateAsesor(id, estado) {
        return this.post('/admin/user/' + id + '/asesor/' + estado);
    }

    updatePlan(id, plan) {
        return this.post('/admin/user/' + id + '/plan/' + plan);
    }

    getModelsResume = (userId) => {
        return this.get('/admin/user/' + userId + '/modelos');
    };

    updateAnnualCompensation(id, value, year) {
        return this.post(
            `/admin/user/${id}/compensacion/${year}`,
            JSON.stringify({
                value,
            }),
        );
    }

    getAnnualCompensation(id, year) {
        return this.get(`/admin/user/${id}/compensacion/${year}`);
    }

    maxIrpf(id, value) {
        return this.post('/admin/user/' + id + '/maximo-irpf/' + value);
    }

    getAgentsPagedWithFilter(page, rows, filter) {
        return this.post(
            '/admin/agentes',
            JSON.stringify({
                page,
                rows,
                filter,
            }),
        );
    }

    removeAgentChild(id) {
        return this.delete('/admin/agente/hijo/' + id);
    }

    addExpertChild(agenteId, hijoId) {
        return this.post('/admin/agente/' + agenteId + '/hijo/' + hijoId);
    }

    addExpert(id) {
        return this.post('/admin/user/' + id + '/agente');
    }

    checkValidUserBy(filter) {
        return this.post(
            '/admin/agente/check-valido',
            JSON.stringify({
                filter,
            }),
        );
    }
}
