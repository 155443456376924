import React, { Component, useRef, useState } from 'react';
import { Row, Col, Modal, DropdownButton, MenuItem } from 'react-bootstrap';
import ModalDownload from '../components/ModalDownload';
import Planes from '../components/ListsOptions/Planes';
import Contabilidad from '../components/ListsOptions/Contabilidad';
import Datetime from 'react-datetime';
import Select from 'react-select';
import UtilsDate from '../assets/utils/Date';
import '../../src/assets/css/react-datetime.css';
import Autonomo from '../components/ListsOptions/Autonomo';
import YesNo from '../components/ListsOptions/YesNo';
import ModalResumeModelsCockpit from './Users/ModalResumeModelsCockpit';
import {
    MultiSelect,
    InputCurrency,
    ServerSideTable,
    SimpleSelect,
    Content,
    ContentToolbelt,
    ContentContainer,
    Label,
    Input,
    ActionButton,
    LinkButton,
    CheckboxToggle,
} from 'book';
import { ModalForm, Input as InputDeclarando, Icon, AlertMessage, P, FileUploader, Divider } from 'declarando';
import V2Repository from '../repositories/V2Repository';
import TarjetaDeclarandoRepository from '../repositories/TarjetaDeclarandoRepository';
import 'react-datepicker/dist/react-datepicker.css';
import { useGetMinMaxDates } from './Users/hooks/useGetMinMaxDates';
import { useSetFechaBajaAutonomo } from './Users/hooks/useSetFechaBajaAutonomo';
import { ToastWrapper, toast, ToastMessage } from '../components/Toast/Toast';
import {DescargarLibrosContables} from "./Users/components/DescargarLibrosContables/DescargarLibrosContables";
import {ContabilidadV2Repository} from "../repositories/ContabilidadV2Repository";
import UserV2Repository from "../repositories/UserV2Repository";
import {
    ModalReglasReglasBloqueoContabilidad
} from "./Users/components/ModalReglasBloqueoContabilidad/ModalReglasReglasBloqueoContabilidad";
import JWT from '../utils/JWT';
import { DescargarInformeRenta } from './Users/components/DescargarInformeRenta/DescargarInformeRenta';

const ModalCompensation = (props) => {
    return (
        <Modal
            data-test={'modal-compensacionIva'}
            id={'ModalCompensation'}
            show={props.modalShown}
            onHide={props.closeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Cuota a compensar de IVA {props.currentYear} del usuario {props.userName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.loading ? (
                    <p>Cargando...</p>
                ) : (
                    <InputCurrency
                        id={'input-compensacionIva'}
                        value={props.compensationValue}
                        onChange={props.updateCompensationValue}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                {props.loading ? (
                    <button className={'btn btn-disabled'}>Cargando...</button>
                ) : (
                    <button data-test={'button-compensacionIva'} className={'btn'} onClick={props.handleSave}>
                        Guardar
                    </button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

const ModalDesbloquearTarjeta = (props) => {
    return (
        <Modal
            data-test={'modal-desbloquear-tarjeta'}
            id={'ModalDesbloquearTarjeta'}
            show={props.modalShown}
            onHide={props.closeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>Desbloquear proceso activación tarjeta del usuario {props.userName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                ¿Estás seguro de que quieres desbloquear el proceso de activación de la tarjeta de este usuario?
                <br />
            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                    <div className="col-md-2">
                        <button
                            data-test={'button-cancel-desbloquear-tarjeta'}
                            className={'btn btn-default'}
                            onClick={props.closeModal}
                        >
                            Cancelar
                        </button>
                    </div>
                    <div className="col-md-3 col-md-offset-7">
                        <button
                            data-test={'button-desbloquear-tarjeta'}
                            className={'btn btn-fill btn-info'}
                            onClick={props.handleSave}
                        >
                            Sí, desbloquear
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

const ModalMaxIrpf = (props) => {
    return (
        <Modal data-test={'modal-maxIrpf'} id={'ModalMaxIrpf'} show={props.modalShown} onHide={props.closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Máximo IRPF del usuario {props.userName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                ¿Estás seguro de que quieres asignar el máximo IRPF en las facturas del cliente?
                <br />
                <div className="row">
                    <div className="col-md-2 col-md-offset-5">
                        <CheckboxToggle
                            onChange={props.handleUpdateCheckBox}
                            checked={props.checkBoxStatus}
                            name={'Active_checkbox'}
                        ></CheckboxToggle>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                    <div className="col-md-2">
                        <button
                            data-test={'button-cancel-maxIrpf'}
                            className={'btn btn-default'}
                            onClick={props.closeModal}
                        >
                            Cancelar
                        </button>
                    </div>
                    <div className="col-md-3 col-md-offset-7">
                        <button
                            data-test={'button-maxIrpf'}
                            className={'btn btn-fill btn-info'}
                            onClick={props.handleSave}
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

class Users extends Component {
    constructor(props) {
        super(props);

        this.userV2Repository = new UserV2Repository();
        this.v2Repository = new V2Repository();
        this.tarjetaDeclarandoRepository = new TarjetaDeclarandoRepository();

        this.currentYear = new Date().getFullYear();
        this.compensationYear = this.currentYear - 1;

        this.state = {
            rolePlanEdit: false,
            filter: '',
            showFilters: false,
            autonomo: Autonomo.ALL_KEY,
            plan: [Planes.FREE_KEY, Planes.ESENCIAL_KEY, Planes.AVANZADO_KEY, Planes.ESTELAR_KEY, Planes.BAJA_KEY],
            initDate: null,
            endDate: null,
            maxDate: null,
            users: [],
            modalDownloadOpen: false,
            modalUserId: null,
            modalModelsShown: false,
            modalModelsUserId: null,
            compensationModalShown: false,
            compensationValue: 0,
            compensationModalLoading: false,
            userId: 0,
            fechaBajaAutonomo: null,
            userName: '',
            maxIrpfModalShown: false,
            maxIrpf: '',
            planChangeable: false,
            forceRefresh: false,
            iaesList: [],
            iae: [],
            desbloquearTarjetaModalShown: false,
            modalFechaBajaAutonomoIsActive: false,
            modalSubidaContabilidadIsActive: false,
            anyosCerrados: [],
        };

        this.modalReglasBloqueoContabilidad = React.createRef();
    }

    componentDidMount() {
        const roles = JWT.parseToken().roles;
        const rolePlanEdit = roles.includes('ROLE_PLAN_EDIT');
        this.setState({rolePlanEdit})
        this.loadIaes();
        const repository = new ContabilidadV2Repository();
        repository.getUltimosAnyosCerrados().then(({anyos}) => {
            const anyosCerrados = anyos.map(year => ({label: year, value: year}));
            this.setState({anyosCerrados})
        });
    }

    loadIaes = () => {
        this.userV2Repository.getAllIaes().then((response) => {
            this.setState({
                iaesList: response.data,
            });
        });
    };

    reloadUsers = () => {
        this.setState({
            forceRefresh: true,
        });
    };

    updateForceRefresh = () => {
        this.setState({
            forceRefresh: false,
        });
    };

    // Filters

    handleFilterChange = (value) => {
        this.setState({
            filter: value,
        });
    };

    handleAutonomoChange = (value) => {
        this.setState({
            autonomo: value,
        });
    };

    handleFilterPlan = (value) => {
        this.setState({
            plan: value,
        });
    };

    handleFilterIae = (value) => {
        this.setState({
            iae: value,
        });
    };

    handleInitDateChange(date) {
        if (date && date.isValid()) {
            this.setState({
                initDate: UtilsDate.formatDateToString(date),
            });
        }
    }

    handleEndDateChange(date) {
        if (date && date.isValid()) {
            this.setState({
                endDate: UtilsDate.formatDateToString(date),
            });
        }
    }

    handleMaxDateChange(date) {
        if (date && date.isValid()) {
            this.setState({
                maxDate: UtilsDate.formatDateToString(date),
            });
        }
    }

    // Actions

    handleToggleshowFilters = () => {
        this.setState(() => ({ showFilters: !this.state.showFilters }));
    };

    handleAccessClick(id, email) {
        this.v2Repository
            .impersonate(id, email)
            .then(() => {
                window.open(process.env.REACT_APP_APP_URL, '_blank');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleSeeAltaFile(id) {
        window.open(process.env.REACT_APP_API_URL + '/v2/admin/fichero/037/' + id);
    }

    handleOpenDownload = (id) => {
        this.setState({
            modalDownloadOpen: true,
            modalUserId: id,
        });
    };

    handleCancelDownloadModel = () => {
        this.setState({
            modalDownloadOpen: false,
            modalUserId: null,
        });
    };

    handleChangePlan = (userId, plan) => {
        this.userV2Repository.updatePlan(userId, plan).then((response) => {
            this.reloadUsers();
        });
    };

    handleChangeContab = (userId, contab) => {
        this.userV2Repository.updateContab(userId, contab).then((response) => {
            this.reloadUsers();
        });
    };

    handleChangeAutonomo = (userId, autonomo) => {
        this.userV2Repository.updateAutonomo(userId, autonomo).then((response) => {
            this.reloadUsers();
        });
    };

    handleChangeRenta = (userId, renta) => {
        this.userV2Repository.updateRenta(userId, renta).then((response) => {
            this.reloadUsers();
        });
    };

    handleChangeConciliacion = (userId, conciliacion) => {
        this.userV2Repository.updateConciliacion(userId, conciliacion).then((response) => {
            this.reloadUsers();
        });
    };

    handleChangeTarjetas = (userId, tarjetas) => {
        this.userV2Repository.updateTarjetas(userId, tarjetas).then((response) => {
            this.reloadUsers();
        });
    };

    handleChangeAsesor = (userId, asesor) => {
        this.userV2Repository.updateAsesor(userId, asesor).then((response) => {
            this.reloadUsers();
        });
    };

    openModalModels = (userId) => {
        this.setState({
            modalModelsShown: true,
            modalModelsUserId: userId,
        });
    };

    handleModelClose = () => {
        this.setState({
            modalModelsShown: false,
            modalModelsUserId: null,
        });
    };

    handleGetCSVClick = () => {
        window.open(
            process.env.REACT_APP_API_URL +
                '/v2/admin/fichero/listado-usuarios?' +
                'page=0' +
                '&rows=2000' +
                '&filter=' +
                this.state.filter +
                '&autonomo=' +
                this.state.autonomo +
                '&initDate=' +
                this.state.dateIni +
                '&endDate=' +
                this.state.dateEnd +
                '&maxDate=' +
                this.state.maxDate +
                '&plan=' +
                this.state.plan +
                '&iae=' +
                this.state.iae,
        );
    };

    handleCompensationModalClose = () => {
        this.setState({
            compensationModalShown: false,
            compensationValue: 0,
            userName: '',
        });
    };

    handleCompensationModalOpen = (data) => {
        this.setState(
            {
                compensationModalLoading: true,
                compensationModalShown: true,
                userName: data.name,
                userId: data.id,
            },
            () => {
                this.userV2Repository.getAnnualCompensation(data.id, this.compensationYear).then((response) => {
                    this.setState({
                        compensationModalLoading: false,
                        compensationValue: response.data.amount,
                    });
                });
            },
        );
    };

    handleCompensationSave = () => {
        this.setState(
            {
                compensationModalLoading: true,
            },
            () => {
                this.userV2Repository
                    .updateAnnualCompensation(this.state.userId, this.state.compensationValue, this.compensationYear)
                    .then((response) => {
                        this.setState({
                            compensationModalLoading: false,
                            compensationModalShown: false,
                            compensationValue: 0,
                            userName: '',
                            userId: null,
                        });
                    });
            },
        );
    };

    updateCompensationValue = (value) => {
        if (value >= 0) {
            this.setState({
                compensationValue: value,
            });
        }
    };

    handleMaxIrpfModalClose = () => {
        this.setState({
            maxIrpfModalShown: false,
            maxIrpf: '',
        });
    };

    handleMaxIrpfModalOpen = (data, maxIrpf) => {
        this.setState({
            maxIrpfModalShown: true,
            userName: data.name,
            userId: data.id,
            maxIrpf: maxIrpf,
        });
    };

    handleDesbloquearTarjetaModalOpen = (data) => {
        this.setState({
            desbloquearTarjetaModalShown: true,
            userName: data.name,
            userId: data.id,
        });
    };

    updateMaxIrpfValue = () => {
        const toggle = this.state.maxIrpf === 0 ? 1 : 0;
        this.setState({
            maxIrpf: toggle,
        });
    };

    handleMaxIrpfSave = () => {
        this.userV2Repository.maxIrpf(this.state.userId, this.state.maxIrpf).then((response) => {
            this.handleMaxIrpfModalClose();
            this.reloadUsers();
        });
    };

    handleDesbloquearTarjetaSave = () => {
        this.tarjetaDeclarandoRepository.desbloquearTarjeta(this.state.userId).then(() => {
            this.setState({
                desbloquearTarjetaModalShown: false,
            });
            this.reloadUsers();
        });
    };

    handleDesbloquearTarjetaModalClose = () => {
        this.setState({
            desbloquearTarjetaModalShown: false,
        });
    };

    changePlanActionable = () => {
        this.setState({
            planChangeable: !this.state.planChangeable,
        });
    };

    openModalFechaBajaAutonomo = (data) => {
        this.setState({
            modalFechaBajaAutonomoIsActive: true,
            fechaBajaAutonomo: data.fechaBajaAutonomo,
            userId: data.id,
        });
    };

    closeModalFechaBajaAutonomo = () => {
        this.setState({
            modalFechaBajaAutonomoIsActive: false,
        });
    };

    openModalSubidaContabilidad = (data) => {
        this.setState({
            modalSubidaContabilidadIsActive: true,
            userId: data.id,
        });
    };

    closeModalSubidaContabilidad = () => {
        this.setState({
            modalSubidaContabilidadIsActive: false,
        });
    };

    render() {

        return (
            <>
                <Content
                    id="gestionUsuarios"
                    title={
                        <span>
                            Gestión d<span onClick={this.changePlanActionable}>e</span> usuarios
                        </span>
                    }
                >
                    <ModalReglasReglasBloqueoContabilidad ref={this.modalReglasBloqueoContabilidad} />
                    <ContentToolbelt>
                        <Row>
                            <Col sm={12}>
                                <ActionButton
                                    title={'Action'}
                                    small
                                    className={'pull-right font-weight-500'}
                                    onClick={this.handleGetCSVClick}
                                >
                                    CSV
                                    <i className={'fa fa-table ml-p5'} />
                                </ActionButton>
                            </Col>
                        </Row>
                    </ContentToolbelt>
                    <ContentContainer>
                        <Row>
                            <Col className={'mb-1'} xs={12} sm={3} md={4}>
                                <Label theme={'primary'}>Identificador</Label>
                                <Input
                                    type="text"
                                    name="filter"
                                    className={'mb-0'}
                                    placeholder="ID, Nombre, Usuario, Email, DNI, Teléfono    ."
                                    value={this.state.filter}
                                    onChange={(e) => this.handleFilterChange(e.target.value)}
                                />
                            </Col>
                            <Col xs={12}>
                                <LinkButton
                                    small
                                    className={'text-primary font-weight-500 text-underline'}
                                    onClick={this.handleToggleshowFilters}
                                >
                                    {this.state.showFilters ? `Mostrar menos filtros` : 'Mostrar más filtros'}
                                </LinkButton>
                            </Col>
                        </Row>
                        {this.state.showFilters && (
                            <div className={'mt-2 mb-2'}>
                                <Row>
                                    <Col className={'mb-1'} xs={12} sm={3} md={3}>
                                        <Label theme={'primary'}>Tipo de plan</Label>
                                        <MultiSelect
                                            id={'select-plans'}
                                            values={this.state.plan}
                                            options={Planes.getFullOptions()}
                                            onChange={(options) => this.handleFilterPlan(options)}
                                        />
                                    </Col>
                                    <Col className={'mb-1'} xs={12} sm={3} md={3}>
                                        <Label theme={'primary'}>Iaes</Label>
                                        <MultiSelect
                                            id={'select-iaes'}
                                            values={this.state.iae}
                                            options={this.state.iaesList}
                                            onChange={(options) => this.handleFilterIae(options)}
                                            placeholder={'Todos'}
                                        />
                                    </Col>
                                    <Col className={'mb-1'} xs={12} sm={3} md={2}>
                                        <Label theme={'primary'}>Tipo de usuario</Label>
                                        <Select
                                            styles={{ minHeight: '40px' }}
                                            value={Autonomo.getOptionOfKey(this.state.autonomo)}
                                            options={Autonomo.getFullOptions()}
                                            onChange={(option) => this.handleAutonomoChange(option.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'mb-1'} xs={12}>
                                        <Label theme={'primary'}>Fecha Registro</Label>
                                    </Col>
                                    <Col className={'mb-1'} sm={3}>
                                        <Datetime
                                            className={''}
                                            dateFormat={UtilsDate.visualFormat}
                                            timeFormat={false}
                                            inputProps={{ placeholder: 'Fecha min. registro' }}
                                            value={this.state.initDate}
                                            onChange={(e) => this.handleInitDateChange(e)}
                                        />
                                    </Col>
                                    <Col className={'mb-1'} sm={3}>
                                        <Datetime
                                            className={''}
                                            dateFormat={UtilsDate.visualFormat}
                                            timeFormat={false}
                                            inputProps={{ placeholder: 'Fecha máx. registro' }}
                                            value={this.state.endDate}
                                            onChange={(e) => this.handleEndDateChange(e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'mb-1'} sm={3}>
                                        <Label theme={'primary'}>Fecha Alta</Label>
                                        <Datetime
                                            className={''}
                                            dateFormat={UtilsDate.visualFormat}
                                            timeFormat={false}
                                            inputProps={{ placeholder: 'Fecha máx. alta' }}
                                            value={this.state.maxDate}
                                            onChange={(e) => this.handleMaxDateChange(e)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        )}
                        <Row className={'mt-4'}>
                            <Col xs={12}>
                                <ServerSideTable
                                    id={'users-table'}
                                    updateStatus={this.state.forceRefresh}
                                    setForceUpdate={this.updateForceRefresh}
                                    handleGet={(page, rows, filters, orders) => {
                                        return this.userV2Repository.getPagedWithFilter(page, rows, filters);
                                    }}
                                    orderable={false}
                                    filters={{
                                        filter: this.state.filter,
                                        autonomo: this.state.autonomo,
                                        initDate: this.state.initDate,
                                        endDate: this.state.endDate,
                                        maxDate: this.state.maxDate,
                                        planes: this.state.plan,
                                        iaes: this.state.iae,
                                    }}
                                    cols={[
                                        {
                                            class: 'tdlabel',
                                            name: 'ID',
                                            data: 'id',
                                        },
                                        {
                                            class: 'tdlabel',
                                            name: 'NOMBRE',
                                            data: 'nombreCompleto',
                                        },
                                        {
                                            class: 'tdlabel',
                                            name: 'IDENT. FISCAL',
                                            data: 'nif',
                                        },
                                        {
                                            class: 'tdlabel',
                                            name: 'EMAIL',
                                            data: 'email',
                                        },
                                        {
                                            class: 'planActivo',
                                            name: 'PLAN',
                                            data: 'plan_id',
                                            handler: (col, row, data) => {
                                                return (
                                                    <SimpleSelect
                                                        disabled={!this.state.rolePlanEdit}
                                                        id={'plan-user-' + row.id}
                                                        value={data}
                                                        options={Planes.getOptions()}
                                                        onChange={(value) => this.handleChangePlan(row.id, value)}
                                                    />
                                                );
                                            },
                                        },
                                        {

                                            name: 'CONTABILIDAD',
                                            class: 'tdlabel',
                                            data: 'hasAccounting',
                                            handler: (col, row, data) => {
                                                return (
                                                    <span>
                                                        {Contabilidad.getOptionFromProfileData(data).label}
                                                    </span>

                                                );
                                            },
                                        },
                                        {
                                            name: 'AUTONOMO',
                                            class: 'tdlabel',
                                            data: 'isAutonomo',
                                            handler: (col, row, data) => {
                                                return (
                                                    <span>
                                                        {YesNo.getOptionFromBoolean(data).label}
                                                    </span>

                                                );
                                            },
                                        },
                                        {
                                            name: 'RENTA',
                                            class: 'tdlabel',
                                            data: 'hasRenta',
                                            handler: (col, row, data) => {
                                                return (
                                                    <span>
                                                        {YesNo.getOptionFromBoolean(data).label}
                                                    </span>
                                                );
                                            },
                                        },
                                        {
                                            name: 'CONCILIACION',
                                            class: 'tdlabel',
                                            data: 'hasConciliacion',
                                            handler: (col, row, data) => {
                                                return (
                                                    <span>
                                                        {YesNo.getOptionFromBoolean(data).label}
                                                    </span>
                                                );
                                            },
                                        },
                                        {
                                            name: 'TARJETAS',
                                            class: 'tdlabel',
                                            data: 'hasTarjetas',
                                            handler: (col, row, data) => {
                                                return (
                                                    <span>
                                                        {YesNo.getOptionFromBoolean(data).label}
                                                    </span>
                                                );
                                            },
                                        },
                                        {
                                            name: 'ASESOR',
                                            class: 'tdlabel',
                                            data: 'hasAsesor',
                                            handler: (col, row, data) => {
                                                return (
                                                    <span>
                                                        {YesNo.getOptionFromBoolean(data).label}
                                                    </span>
                                                );
                                            },
                                        },
                                        {
                                            name: 'MODELOS',
                                            data: null,
                                            handler: (col, row, data) => {
                                                return (
                                                    <div className={'text-center'}>
                                                        <span
                                                            onClick={() => this.openModalModels(row.id)}
                                                            title={'Modelos'}
                                                            style={{
                                                                cursor: 'pointer',
                                                                width: '32px',
                                                                height: '32px',
                                                                lineHeight: '32px',
                                                                display: 'inline-block',
                                                                borderRadius: '50%',
                                                                backgroundColor: 'grey',
                                                            }}
                                                        >
                                                            M
                                                        </span>
                                                    </div>
                                                );
                                            },
                                        },
                                        {
                                            name: 'ACCIONES',
                                            data: null,
                                            handler: (col, row, data) => {
                                                return (
                                                    <div>
                                                        <DropdownButton
                                                            bsStyle={'primary'}
                                                            title={'Acciones'}
                                                            key={'dpdacciones'}
                                                            id={`dropdown-basic`}
                                                        >
                                                            <MenuItem
                                                                onClick={() =>
                                                                    this.handleAccessClick(row.id, row.email)
                                                                }
                                                            >
                                                                <i className={'fa fa-user'} /> Acceder a este usuario
                                                            </MenuItem>
                                                            {row.plan_id === Planes.FREE_KEY ? null : (
                                                                <MenuItem
                                                                    onClick={() => this.handleOpenDownload(row.id)}
                                                                >
                                                                    <i className={'fa fa-arrow-circle-o-down'} />{' '}
                                                                    Descargar Modelos
                                                                </MenuItem>
                                                            )}
                                                            {row.alta037.valid && (
                                                                <MenuItem
                                                                    onClick={() => this.handleSeeAltaFile(row.id)}
                                                                >
                                                                    037 {'Descargar fichero de alta en Hacienda'}
                                                                </MenuItem>
                                                            )}
                                                            <MenuItem
                                                                onClick={() => this.handleCompensationModalOpen(row)}
                                                            >
                                                                <i className="fa fa-money" aria-hidden="true" /> Cuota
                                                                IVA a compensar
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() =>
                                                                    this.handleMaxIrpfModalOpen(row, row.maxIrpf)
                                                                }
                                                            >
                                                                <i className="fa fa-arrow-up" aria-hidden="true" /> Usar
                                                                máximo IRPF
                                                            </MenuItem>
                                                            <MenuItem
                                                                data-test={'button-contabilidad-presentada-bloqueada'}
                                                                onClick={() => {
                                                                    this.modalReglasBloqueoContabilidad.current.openForm(row.id)
                                                                }}
                                                            >
                                                                <i className={'fa fa-unlock-alt'} aria-hidden="true" /> Reglas bloqueo contabilidad
                                                            </MenuItem>
                                                            {Boolean(row.hasTarjetas === 1 && row.tarjetaBloqueada) && (
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        this.handleDesbloquearTarjetaModalOpen(row)
                                                                    }
                                                                >
                                                                    <i className={'fa fa-credit-card'} /> Desbloquear
                                                                    usuario tarjeta
                                                                </MenuItem>
                                                            )}

                                                            <MenuItem
                                                                onClick={() => this.openModalFechaBajaAutonomo(row)}
                                                            >
                                                                <i className="fa fa-calendar" /> Informar fecha baja de
                                                                autónomo
                                                            </MenuItem>

                                                            <MenuItem
                                                                onClick={() => this.openModalSubidaContabilidad(row)}
                                                            >
                                                                <Icon name="bill" /> Subida de contabilidad
                                                            </MenuItem>
                                                            <DescargarLibrosContables row={row} years={this.state.anyosCerrados}/>
                                                            <DescargarInformeRenta row={row} />
                                                        </DropdownButton>
                                                    </div>
                                                );
                                            },
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </ContentContainer>
                </Content>
                {this.state.compensationModalShown && (
                    <ModalCompensation
                        modalShown={true}
                        closeModal={this.handleCompensationModalClose}
                        handleSave={this.handleCompensationSave}
                        updateCompensationValue={this.updateCompensationValue}
                        compensationValue={this.state.compensationValue}
                        userName={this.state.userName}
                        loading={this.state.compensationModalLoading}
                        currentYear={this.compensationYear}
                    />
                )}
                {this.state.maxIrpfModalShown && (
                    <ModalMaxIrpf
                        modalShown={true}
                        closeModal={this.handleMaxIrpfModalClose}
                        handleSave={this.handleMaxIrpfSave}
                        checkBoxStatus={this.state.maxIrpf}
                        handleUpdateCheckBox={this.updateMaxIrpfValue}
                        userName={this.state.userName}
                    />
                )}

                {this.state.desbloquearTarjetaModalShown && (
                    <ModalDesbloquearTarjeta
                        modalShown={true}
                        closeModal={this.handleDesbloquearTarjetaModalClose}
                        handleSave={this.handleDesbloquearTarjetaSave}
                        userName={this.state.userName}
                    />
                )}
                {this.state.modalModelsShown && (
                    <ModalResumeModelsCockpit
                        handleClose={this.handleModelClose}
                        userId={this.state.modalModelsUserId}
                    />
                )}
                {this.state.modalDownloadOpen && (
                    <ModalDownload
                        id={'modal-download'}
                        open={true}
                        title={'Descargas Modelos'}
                        action={'Descargar'}
                        userId={this.state.modalUserId}
                        handleCancel={this.handleCancelDownloadModel}
                    />
                )}
                {
                    <FechaBajaAutonomo
                        userId={this.state.userId}
                        isOpen={this.state.modalFechaBajaAutonomoIsActive}
                        closeModal={this.closeModalFechaBajaAutonomo}
                        reloadUsers={this.reloadUsers}
                        fechaBajaAutonomo={this.state.fechaBajaAutonomo}
                    />
                }
                {
                    <SubidaContabilidad
                        userId={this.state.userId}
                        isOpen={this.state.modalSubidaContabilidadIsActive}
                        closeModal={this.closeModalSubidaContabilidad}
                        reloadUsers={this.reloadUsers}
                    />
                }
            </>
        );
    }
}

const SubidaContabilidad = ({ userId, isOpen, closeModal, reloadUsers }) => {
    const [formsData, setFormsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const userRepository = new V2Repository();

    const handleChange = (formData) => {
        for (const pair of formData.entries()) {
            const object = { [pair[0]]: pair[1] };
            setFormsData([...formsData, object]);
        }
    };

    const handleDeleteFormData = (key) => {
        const newFormsData = formsData.filter((formData) => {
            return !formData[key];
        });
        setFormsData(newFormsData);
    };

    const handleCloseModal = () => {
        setFormsData([]);
        closeModal();
    };

    const handleSave = async () => {
        console.log('userId', userId);
        const data = new FormData();

        formsData.forEach((formData) => {
            data.append(Object.keys(formData)[0], Object.values(formData)[0]);
        });
        setIsLoading(true);
        toast(
            <ToastMessage
                title="Subida de contabilidad iniciada"
                message="Tu contabilidad se está subiendo correctamente, este proceso tardará unos minutos"
            />,
            {
                type: 'success',
                icon: <Icon name="check-circle" color="#30C79A" fontSize="28px" data-notify="icon" />,
            },
        );

        //Delay proposed by product team
        const delay = (callback) => setTimeout(callback, 1000);

        userRepository
            .sendContability(userId, data)
            .then(async (response) => {
                console.log(response);
                if (response.status === 200) {
                    //error
                    const blob = await response.blob();
                    const newBlob = new Blob([blob]);

                    const blobUrl = window.URL.createObjectURL(newBlob);

                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.setAttribute('download', `subida_contabilidad_fallida.zip`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);

                    // clean up Url
                    window.URL.revokeObjectURL(blobUrl);
                    throw new Error('Error en la subida de contabilidad');
                }
                if (response.status === 201) {
                    delay(() =>
                        toast(
                            <ToastMessage
                                title="Contabilidad subida correctamente"
                                message="Tu contabilidad se ha subido con éxito"
                            />,
                            {
                                type: 'success',
                                icon: <Icon name="check-circle" color="#30C79A" fontSize="28px" data-notify="icon" />,
                            },
                        ),
                    );
                } else {
                    delay(() =>
                        toast(
                            <ToastMessage
                                title="Error en uno o más archivos"
                                message="Comprueba que los archivos se hayan cargado en el lugar que corresponde y vuelve a iniciar la subida de la contabilidad"
                            />,
                            {
                                type: 'error',
                                icon: <Icon name="times-circle" color="#FF0000" fontSize="28px" data-notify="icon" />,
                            },
                        ),
                    );
                }
            })
            .catch((error) => {
                toast(
                    <ToastMessage
                        title="Error en la subida de contabilidad"
                        message="Ha ocurrido un error, en unos minutos se descargarán uno o varios archivos con el detalle"
                    />,
                    {
                        type: 'error',
                        icon: <Icon name="times-circle" color="#FF0000" fontSize="28px" data-notify="icon" />,
                    },
                );
            })
            .finally(() => {
                setIsLoading(false);
                setFormsData([]);
                closeModal();
            });
    };

    return (
        <>
            <ToastWrapper />
            <ModalForm
                isOpen={isOpen}
                title="Subida de contabilidad"
                labelAction="Subir contabilidad"
                handleCancel={() => handleCloseModal()}
                handleAction={() => handleSave()}
                busy={isLoading}
                disabledAction={formsData.length === 0}
            >
                <AlertMessage
                    full
                    tag="Recuerda:"
                    message={
                        <>
                            <P fontSize="sm">1. El formato del archivo debe ser CSV y no exceder los 5MB.</P>
                            <P>
                                2. Si hay contabilidad, proveedores o clientes antes de la subida de un nuevo CSV, se
                                eliminarán y se reemplazarán por los datos del nuevo archivo.
                            </P>
                        </>
                    }
                    tone="info"
                />
                <div className="reduce-div-paddings" style={{ overflow: 'hidden', marginTop: 8 }}>
                    <FileUploader
                        variant="secondary"
                        fileKey={'ingreso_con_factura'}
                        file={''}
                        handleStore={(formData) => handleChange(formData)}
                        handleGet={() => {}}
                        handleDelete={() => {}}
                        handleDeleteTemporal={() => handleDeleteFormData('ingreso_con_factura')}
                        fileTypes={'.csv'}
                        textButton="Subir el archivo del gasto"
                        label="Ingresos con factura"
                        textDeleteButton="Borrar"
                        messageMaxSize="El CSV no debe exceder los 5MB"
                    />
                    <Divider />
                    <FileUploader
                        variant="secondary"
                        fileKey={'ingreso_sin_factura'}
                        file={''}
                        handleStore={(formData) => handleChange(formData)}
                        handleGet={() => {}}
                        handleDelete={() => {}}
                        handleDeleteTemporal={() => handleDeleteFormData('ingreso_sin_factura')}
                        fileTypes={'.csv'}
                        textButton="Subir el archivo del gasto"
                        label="Ingresos sin factura"
                        textDeleteButton="Borrar"
                        messageMaxSize="El CSV no debe exceder los 5MB"
                    />
                    <Divider />
                    <FileUploader
                        variant="secondary"
                        fileKey={'ingreso_simplificado'}
                        file={''}
                        handleStore={(formData) => handleChange(formData)}
                        handleGet={() => {}}
                        handleDelete={() => {}}
                        handleDeleteTemporal={() => handleDeleteFormData('ingreso_simplificado')}
                        fileTypes={'.csv'}
                        textButton="Subir el archivo del gasto"
                        label="Ingresos fact. simplificada"
                        textDeleteButton="Borrar"
                        messageMaxSize="El CSV no debe exceder los 5MB"
                    />
                    <Divider />
                    <FileUploader
                        variant="secondary"
                        fileKey={'ingreso_subvencion'}
                        file={''}
                        handleStore={(formData) => handleChange(formData)}
                        handleGet={() => {}}
                        handleDelete={() => {}}
                        handleDeleteTemporal={() => handleDeleteFormData('ingreso_subvencion')}
                        fileTypes={'.csv'}
                        textButton="Subir el archivo del gasto"
                        label="Ingresos subvención"
                        textDeleteButton="Borrar"
                        messageMaxSize="El CSV no debe exceder los 5MB"
                    />
                    <Divider />
                    <FileUploader
                        variant="secondary"
                        fileKey={'gasto_con_factura'}
                        file={''}
                        handleStore={(formData) => handleChange(formData)}
                        handleGet={() => {}}
                        handleDelete={() => {}}
                        fileTypes={'.csv'}
                        handleDeleteTemporal={() => handleDeleteFormData('gasto_con_factura')}
                        textButton="Subir el archivo del gasto"
                        label="Gastos con factura"
                        textDeleteButton="Borrar"
                        messageMaxSize="El CSV no debe exceder los 5MB"
                    />
                    <Divider />
                    <FileUploader
                        variant="secondary"
                        fileKey={'gasto_sin_factura'}
                        file={''}
                        handleStore={(formData) => handleChange(formData)}
                        handleGet={() => {}}
                        handleDelete={() => {}}
                        fileTypes={'.csv'}
                        handleDeleteTemporal={() => handleDeleteFormData('gasto_sin_factura')}
                        textButton="Subir el archivo del gasto"
                        label="Gastos sin factura"
                        textDeleteButton="Borrar"
                        messageMaxSize="El CSV no debe exceder los 5MB"
                    />
                    <Divider />
                </div>
            </ModalForm>
        </>
    );
};

const FechaBajaAutonomo = ({ userId, isOpen, closeModal, fechaBajaAutonomo, reloadUsers }) => {
    const [newFechaBajaAutonomo, setNewFechaBajaAutonomo] = useState(fechaBajaAutonomo);
    const [status, setStatus] = useState({ error: false, isLoading: false, success: false });
    const { minMaxDatesAllowed } = useGetMinMaxDates();
    const { postFechaBajaAutonomo } = useSetFechaBajaAutonomo();
    const inputRef = useRef();

    const handleAction = () => {
        setStatus({ ...status, isLoading: true });
        postFechaBajaAutonomo(userId, newFechaBajaAutonomo)
            .then((data) => {
                setStatus({ error: null, isLoading: false, success: data.success });
                setTimeout(() => {
                    closeModalForm();
                }, 2000);
            })
            .catch((error) => setStatus({ error: error.message, isLoading: false, success: false }));
    };

    const closeModalForm = () => {
        setStatus({ error: false, isLoading: false, success: false });
        setNewFechaBajaAutonomo(fechaBajaAutonomo);
        console.log('Refreshing');
        closeModal();
        reloadUsers();
    };

    const clickIcon = () => {
        if (inputRef.current) {
            inputRef.current.showPicker();
        }
    };
    return (
        <>
            <ModalForm
                isOpen={isOpen}
                title="Informar fecha de baja de autónomo"
                handleCancel={() => closeModalForm()}
                handleAction={() => handleAction()}
                busy={status.isLoading}
                successChildren={`La fecha de baja del autónomo es ${newFechaBajaAutonomo}`}
                onSuccess={() => closeModalForm()}
                success={status.success}
                labelAction="Guardar fecha"
            >
                <InputDeclarando
                    id={'fechaBajaAutonomo'}
                    ref={inputRef}
                    error={'error'}
                    label="Fecha de baja de autónomo"
                    noPB
                    onBlur={function noRefCheck() {}}
                    onChange={(e) => setNewFechaBajaAutonomo(e.target.value)}
                    onFocus={function noRefCheck() {}}
                    success={false}
                    type="date"
                    min={minMaxDatesAllowed.minDate}
                    max={minMaxDatesAllowed.maxDate}
                    defaultValue={fechaBajaAutonomo}
                    icon="calender"
                    iconClickHandler={clickIcon}
                />
                {status.error ? status.error : null}
            </ModalForm>
        </>
    );
};

export default Users;
