import { useEffect, useState } from 'react';
import { formatISO, addDays, subDays } from 'date-fns';

const useGetMinMaxDates = () => {
    const [minMaxDatesAllowed, setMinMaxDatesAllowed] = useState({});

    useEffect(() => {
        const todayDate = new Date();

        const minDate = formatISO(subDays(todayDate, 30), { representation: 'date' });
        const maxDate = formatISO(addDays(todayDate, 30), { representation: 'date' });

        setMinMaxDatesAllowed({ minDate, maxDate });
    }, []);

    return { minMaxDatesAllowed };
};

export { useGetMinMaxDates };
