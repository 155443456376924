import React, { Component } from 'react';

export class HomeCard extends Component {
    render() {
        return (
            <div className="card card-stats" id={this.props.id}>
                <div className="content">
                    <div
                        className="row"
                        onClick={this.props.onTitleClick}
                        style={this.props.onTitleClick ? { cursor: 'pointer' } : null}
                    >
                        <div className="col-xs-12">
                            <p className={'font-weight-500'}>
                                <i className={'fa ' + this.props.bigIcon} /> {this.props.text}
                            </p>
                        </div>
                        <div className="col-xs-12">
                            <div className="numbers">
                                {this.props.value}
                                {this.props.symbol}
                            </div>
                            {this.props.porcentaje !== undefined ? (
                                <div className="numbers" title={'Crecimiento mensual'}>
                                    {this.props.porcentaje >= 0 ? (
                                        <p style={{ color: 'blue' }}>{this.props.porcentaje} %</p>
                                    ) : (
                                        <p style={{ color: 'red' }}>{this.props.porcentaje} %</p>
                                    )}
                                </div>
                            ) : (
                                <div className="numbers" style={{ paddingBottom: '24px' }}></div>
                            )}
                        </div>
                    </div>
                </div>
                {this.props.footer ? (
                    <div className="footer">
                        <hr />
                        <div className="stats">{this.props.footer}</div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default HomeCard;
