import V2Repository from "./V2Repository";

export class ContabilidadV2Repository extends V2Repository {
    getUltimosAnyosCerrados() {
        return this.get('/admin/contabilidad/ultimos-anyos-cerrados');
    }

    descargarCsvGastosConFactura(userId, anyo) {
        return this.downloadFile(this.apiPath + `/admin/users/${userId}/libros-contables/${anyo}/gastos-con-factura`);
    }

    descargarCsvIngresosConFactura(userId, anyo) {
        return this.downloadFile(this.apiPath + `/admin/users/${userId}/libros-contables/${anyo}/ingresos-con-factura`);
    }

    descargarCsvIngresos(userId, anyo) {
        return this.downloadFile(this.apiPath + `/admin/users/${userId}/libros-contables/${anyo}/ingresos`);
    }

    descargarCsvGastos(userId, anyo) {
        return this.downloadFile(this.apiPath + `/admin/users/${userId}/libros-contables/${anyo}/gastos`);
    }


    getReglasContabilidad(userId, year) {
        return this.get(`/admin/users/${userId}/reglas-contabilidad/${year}`);
    }

    postReglasContabilidad(userId, trimestre, year, desactivadas) {
        if(desactivadas){
            return this.post(`/admin/users/${userId}/reglas-contabilidad`, JSON.stringify({
                year,
                trimestre
            }));
        }
        return this.delete(`/admin/users/${userId}/reglas-contabilidad`, JSON.stringify({
            year,
            trimestre
        })); 
    }
}
