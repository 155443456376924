class AdviceStatus {
    static ALL = 'Todos';
    static ACTIVE = 'Activos';
    static PAUSED = 'Pausados';

    static ALL_KEY = 'all';
    static ACTIVE_KEY = 'active';
    static PAUSED_KEY = 'paused';

    static data = [
        {
            key: AdviceStatus.ALL_KEY,
            label: AdviceStatus.ALL,
        },
        {
            key: AdviceStatus.ACTIVE_KEY,
            label: AdviceStatus.ACTIVE,
        },
        {
            key: AdviceStatus.PAUSED_KEY,
            label: AdviceStatus.PAUSED,
        },
    ];
}

export default AdviceStatus;
