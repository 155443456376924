import AdminV2Repository from "./AdminV2Repository";

export default class ProductsRepository extends AdminV2Repository {
    getProducts(page, rows, filter) {
        return this.post(
            '/productos',
            JSON.stringify({
                page,
                rows,
                filter,
            }),
        );
    }

    saveProduct(data) {
        return this.post(
            '/producto',
            JSON.stringify({
                data,
            }),
        );
    }

    deleteProduct(id) {
        return this.delete('/producto/' + id);
    }
}
